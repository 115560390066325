import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Terms = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full flex justify-center">
      <section className="container py-[60px] font-Inter sm:py-[30px]">
        <h1 className=" font-bold text-[#333333] xl:text-[50px] lg:text-[50px] md:text-[text-50px] sm:text-[35px] leading-5 tracking-tight text-center">
          {t('TermsPage.heading')}
        </h1>
        <p className="pt-10 text-[#333333] text-3xl font-bold font-Inter tracking-[0.2px]">
          {t('TermsPage.chapter')}
        </p>

        <div className="flex flex-col gap-y-5 py-3">
          <div className="">
            <p className="font-bold text-[#333333] text-2xl p-2">
              {t('TermsPage.article1')}
            </p>
            <ul className="font-medium text-[#666666] font-Inter list-decimal px-3">
              <li className="mx-3 my-1">
                {t('TermsPage.11a')}
                <span className=""></span>{" "}
                <Link to={"https://www.keywordlit.com"}>
                  <span>(https://www.keywordlit.com),
                  </span>
                </Link>
                {t('TermsPage.11b')}
              </li>
            </ul>
          </div>
          <div className="">
            <p className="font-bold text-[#333333] text-2xl p-2">
              {t('TermsPage.article2')}
            </p>
            <ul className="font-medium text-[#666666] font-Inter list-decimal px-3">
              <li className="mx-3 my-1">
                {t('TermsPage.11')}
              </li>
              <li className="mx-3 my-1">
                {t('TermsPage.12')}
              </li>
            </ul>
          </div>
          <div className="">
            <p className="font-bold text-[#333333] text-2xl p-2">
              {t('TermsPage.article3')}
            </p>
            <ul className="font-medium text-[#666666] font-Inter list-decimal px-3">
              <li className="mx-3 my-1">
                {t('TermsPage.31')}
              </li>
              <li className="mx-3 my-1">
                {t('TermsPage.31')}
              </li>
              <li className="mx-3 my-1">
                {t('TermsPage.33')}
              </li>
            </ul>
          </div>
          <div className="">
            <p className="font-bold text-[#333333] text-2xl p-2">
              {t('TermsPage.article4')}

            </p>
            <ul className="font-medium text-[#666666] font-Inter list-decimal px-3">
              <li className="mx-3 my-1">
                {t('TermsPage.41')}

              </li>
              <li className="mx-3 my-1">
                {t('TermsPage.42')}

              </li>
            </ul>
          </div>
          <div>
            <p className="font-bold text-[#333333] text-2xl p-2">
              {t('TermsPage.article5')}
            </p>
            <ul className="font-medium text-[#666666] font-Inter list-decimal px-3">
              <li className="mx-3 my-1">
                {t('TermsPage.51')}
              </li>
              <li className="mx-3 my-1">
                {t('TermsPage.52')}
              </li>
              <li className="mx-3 my-1">
                {t('TermsPage.5a')}
              </li>
              <li className="mx-3 my-1">
                {t('TermsPage.5b')}
              </li>
              <li className="mx-3 my-1">
                {t('TermsPage.5c')}
              </li>
            </ul>
          </div>
          <div>
            <p className="font-bold text-[#333333] text-2xl p-2">{t('TermsPage.article6')}</p>
            <ul className="font-medium text-[#666666] font-Inter list-decimal px-3 mx-3">
              <li>
                {t('TermsPage.61')}
              </li>
              <li>
                {t('TermsPage.62')}
              </li>
              <li>
                {t('TermsPage.63')}
              </li>
              <li>
                {t('TermsPage.64')}
              </li>
              <li>
                {t('TermsPage.65')}
              </li>
              <li>
                {t('TermsPage.66')}
              </li>
              <li>
                {t('TermsPage.67')}
              </li>
              <li>
                {t('TermsPage.68')}
              </li>
            </ul>
          </div>
          <div>
            <p className="font-bold text-[#333333] text-2xl p-2">{t('TermsPage.article7')}</p>
            <ul className="font-medium text-[#666666] font-Inter list-decimal px-3 mx-3">
              <li>
                {t('TermsPage.71')}
              </li>
              <li>
                {t('TermsPage.72')}
              </li>
              <li>
                {t('TermsPage.73')}
              </li>
              <li>
                {t('TermsPage.74')}
              </li>
              <li>
                {t('TermsPage.75')}
              </li>
              <li>
                {t('TermsPage.76')}
              </li>
            </ul>
          </div>
          <div>
            <p className="font-bold text-[#333333] text-2xl p-2">{t('TermsPage.article8')}</p>
            <ul className="font-medium text-[#666666] font-Inter list-decimal px-3 mx-3">
              <li>
                {t('TermsPage.81')}
              </li>
              <li>
                {t('TermsPage.82')}
              </li>
              <li>
                {t('TermsPage.83')}
              </li>
              <li>
                {t('TermsPage.84')}
              </li>
              <li>
                {t('TermsPage.85')}
              </li>
              <li>
                {t('TermsPage.86')}
              </li>
            </ul>
          </div>
          <div>
            <p className="font-bold text-[#333333] text-2xl p-2">{t('TermsPage.article9')}</p>
            <ul className="font-medium text-[#666666] font-Inter list-decimal px-3 mx-3">
              <li>
                {t('TermsPage.91')}
              </li>
              <li>
                {t('TermsPage.92')}
              </li>
              <li>
                {t('TermsPage.92a')}
              </li>
              <li>
                {t('TermsPage.93')}
              </li>
              <li>
                {t('TermsPage.94')}
              </li>
              <li>
                {t('TermsPage.95')}
              </li>
              <li>
                {t('TermsPage.96')}
              </li>
              <li>
                {t('TermsPage.97')}
              </li>
              <li>
                {t('TermsPage.98')}
              </li>
            </ul>
          </div>
          <div>
            <p className="font-bold text-[#333333] text-2xl p-2">{t('TermsPage.article10')}</p>
            <ul className="font-medium text-[#666666] font-Inter list-decimal px-3 mx-3">
              <li>
                {t('TermsPage.101')}
              </li>
              <li>
                {t('TermsPage.102')}
              </li>
              <li>
                {t('TermsPage.103')}
              </li>
              <li>
                {t('TermsPage.104')}
              </li>
              <li>{t('TermsPage.105')}</li>
              <li>
                {t('TermsPage.106')}
              </li>
              <li>
                {t('TermsPage.107')}
              </li>
            </ul>
          </div>
          <div>
            <p className="font-bold text-[#333333] text-2xl p-2">
              {t('TermsPage.article11')}
            </p>
            <ul className="font-medium text-[#666666] font-Inter list-decimal px-3 mx-3">
              <li>
                {t('TermsPage.111')}
              </li>
              <li>
                {t('TermsPage.112')}
              </li>
              <li>
                {t('TermsPage.113')}
              </li>
            </ul>
          </div>
          <div>
            <p className="font-bold text-[#333333] text-2xl p-2">
              {t('TermsPage.article12')}
            </p>
            <ul className="font-medium text-[#666666] font-Inter list-decimal px-3 mx-3">
              <li>
                {t('TermsPage.121')}
              </li>
              <li>
                {t('TermsPage.122')}
              </li>
              <li>
                {t('TermsPage.122a')}
              </li>
              <li>
                {t('TermsPage.122b')}
              </li>
              <li>{t('TermsPage.122c')}</li>
              <li>{t('TermsPage.122d')}</li>
              <li>
                {t('TermsPage.122e')}
              </li>
              <li>
                {t('TermsPage.122f')}
              </li>
              <li>
                {t('TermsPage.122g')}
              </li>
              <li>
                {t('TermsPage.122h')}
              </li>
              <li>
                {t('TermsPage.122i')}
              </li>
              <li>
                {t('TermsPage.122j')}
              </li>
              <li>{t('TermsPage.122k')}</li>
              <li>
                {t('TermsPage.122l')}
              </li>
              <li>
                {t('TermsPage.123')}
              </li>
              <li>
                {t('TermsPage.124')}
              </li>
              <li>
                {t('TermsPage.125')}
              </li>
            </ul>
          </div>
          <div>
            <p className="font-bold text-[#333333] text-2xl p-2">{t('TermsPage.article12b')}</p>
            <ul className="font-medium text-[#666666] font-Inter list-decimal px-3 mx-3">
              <li>
                {t('TermsPage.12b1')}
              </li>
              <li>
                {t('TermsPage.12b2')}
              </li>
              <li>
                {t('TermsPage.12b3')}
              </li>
              <li>
                {t('TermsPage.12b3a')}
              </li>
              <li>
                {t('TermsPage.12b3b')}
              </li>
              <li>
                {t('TermsPage.12b3c')}
              </li>
              <li>
                {t('TermsPage.12b3d')}
              </li>
              <li>
                {t('TermsPage.12b3e')}
              </li>
              <li>
                {t('TermsPage.12b3f')}
              </li>
              <li>
                {t('TermsPage.12b3g')}
              </li>
              <li>
                {t('TermsPage.12b3h')}
              </li>
              <li>
                {t('TermsPage.12b3i')}
              </li>
              <li>
                {t('TermsPage.12b4')}
              </li>
            </ul>
          </div>
          <div>
            <p className="font-bold text-[#333333] text-2xl p-2">{t('TermsPage.article13')}</p>
            <ul className="font-medium text-[#666666] font-Inter list-decimal px-3 mx-3">
              <li>
                {t('TermsPage.131')}
              </li>
              <li>
                {t('TermsPage.132')}
              </li>
              <li>
                {t('TermsPage.133')}
              </li>
              <li>
                {t('TermsPage.134')}
              </li>
              <li>
                {t('TermsPage.135')}
              </li>
              <li>
                {t('TermsPage.136')}
              </li>
              <li>
                {t('TermsPage.137')}
              </li>
              <li>
                {t('TermsPage.138')}
              </li>
              <li>
                {t('TermsPage.139')}
              </li>
              <li>
                {t('TermsPage.1310')}
              </li>
              <li>
                {t('TermsPage.1311')}
              </li>
              <li>
                {t('TermsPage.1312')}
              </li>
              <li>
                {t('TermsPage.1313')}
              </li>
            </ul>
          </div>
          <div>
            <p className="font-bold text-[#333333] text-2xl p-2">{t('TermsPage.article14')}</p>
            <ul className="font-medium text-[#666666] font-Inter list-decimal px-3 mx-3">
              <li>
                {t('TermsPage.141')}
              </li>
              <li>
                {t('TermsPage.142')}
              </li>
            </ul>
          </div>
          <div>
            <p className="font-bold text-[#333333] text-2xl p-2">{t('TermsPage.article15')}</p>
            <ul className="font-medium text-[#666666] font-Inter list-decimal px-3 mx-3">
              <li>
                {t('TermsPage.151')}
              </li>
              <li>
                {t('TermsPage.152')}
              </li>
            </ul>
          </div>
          <div>
            <p className="font-bold text-[#333333] text-2xl p-2">{t('TermsPage.article16')}</p>
            <ul className="font-medium text-[#666666] font-Inter list-decimal px-3 mx-3">
              <li>
                {t('TermsPage.161')}
              </li>
              <li>
                {t('TermsPage.162')}
              </li>
            </ul>
          </div>
          <div>
            <p className="font-bold text-[#333333] text-2xl p-2">{t('TermsPage.article17')}</p>
            <ul className="font-medium text-[#666666] font-Inter list-decimal px-3 mx-3">
              <li>
                {t('TermsPage.171')}
              </li>
              <li>
                {t('TermsPage.172')}
              </li>
            </ul>
          </div>
          <div>
            <p className="font-bold text-[#333333] text-2xl p-2">
              {t('TermsPage.article17')}
            </p>
            <ul className="font-medium text-[#666666] font-Inter list-decimal px-3 mx-3">
              <li>
                {t('TermsPage.181')}
              </li>
              <li>
                {t('TermsPage.182')}
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>

  );
};

export default Terms;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { SLICE_BASE_NAME } from './constants.js'
import { apiGetYoutubeTagDetail } from '../../../services/YoutubeTagDetailService.js'

const initialState = {
  loading: '',
  youtubetagDetail: {},
}

export const getYoutubeTagDetail = createAsyncThunk(
  SLICE_BASE_NAME + '/data/getList',
  async (data) => {
    const response = await apiGetYoutubeTagDetail(data)
    return response?.data
  }
)

const youtubetagDetailSlice = createSlice({
  name: `${SLICE_BASE_NAME}`,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getYoutubeTagDetail.fulfilled, (state, { payload }) => {
        state.youtubetagDetail = payload
        state.loading = false
      })
      .addCase(getYoutubeTagDetail.pending, (state) => {
        state.loading = true
      })
  },
})

export const { } = youtubetagDetailSlice.actions
export default youtubetagDetailSlice.reducer

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/common/Navbar";
import Footer from "./components/common/Footer";
// import Home from "./pages/Home";
// import Login from "./components/auth/Login";
// import Register from "./components/auth/Register";
// import ForgotPassword from "./components/auth/ForgotPassword";
import Terms from "./pages/Terms";
// import Policy from "./pages/Policy";
import store, { persistor } from "./store";

import "./App.css";
// import SearchTags from "./pages/SearchTags";
// import Settings from "./pages/Settings";
import React, { Suspense } from "react";
import YtTest from "./pages/YtTest";

function App() {
  const Login = React.lazy(() => import("./components/auth/Login"));
  const Register = React.lazy(() => import("./components/auth/Register"));
  const SearchTags = React.lazy(() => import("./pages/SearchTags"));
  const TermsService = React.lazy(() => import("./pages/Terms"));
  const PrivacyPolicy = React.lazy(() => import("./pages/Policy"));
  const Setting = React.lazy(() => import("./pages/Settings"));
  const Home = React.lazy(() => import("./pages/Home"));
  const ForgotPassword = React.lazy(() =>
    import("./components/auth/ForgotPassword")
  );
  const Credit = React.lazy(() => import("./pages/Credit"));
  const loader = () => {
    <div class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>;
  };
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={loader}>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/search-tags" element={<SearchTags />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/terms-service" element={<TermsService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/settings" element={<Setting />} />
            <Route path="/add-funds" element={<Credit />} />
            <Route path="/yt-test" element={<YtTest />} />

          </Routes>
          <Footer />
        </Suspense>
      </PersistGate>
    </Provider>
  );
}

export default App;

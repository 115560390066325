import { combineReducers } from '@reduxjs/toolkit'
import youtubeTag from './youtubeTagSlice'
import youtubeData from './youtubeTagSlice'

const reducer = combineReducers({
  youtubeTag,
  youtubeData
})

export * from './youtubeTagSlice'

export default reducer
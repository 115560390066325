import i18n from "i18next";
import i18nextBrowserLanguagedetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import backend from "i18next-http-backend";

const DETECTION_OPTIONS = {
  order: ["localStorage", "navigator"],
  caches: ["localStorage"],
  lookupLocalStorage: "lang",
};

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      Home: {
        "How to use": "How to use",
        "Master the art": "Master the Art of Keyword Research",
        "Unlock the secret": "Unlock the Secrets of SEO Success",
        "Enhance Your Online": "Enhance Your Online Visibility and Traffic",
        "Register and add your fund": "Register and add your fund",
        "Signup easily":
          "Sign up easily and add funds to your account to access premium keyword research tools",
        "Search The Keywords": "Search The Keywords",
        "Utilize our advanced search":
          "Utilize our advanced search feature to discover high-performing keywords tailored to your niche.",
        "Analysis the Data": "Analysis the Data",
        "Leverage our detailed":
          "Leverage our detailed analytics to understand keyword performance and optimize your content strategy",
        "Visualizing the Complexity of Keywords":
          " Visualizing the Complexity of Keywords",
        "Explore the intricate network of keyword":
          "Explore the intricate network of keyword relationships with our dynamic visualization tool. Each node represents a unique keyword, connected by lines that signify their relevance and co-occurrence in search queries. Dive into the digital ecosystem to identify and capitalize on the most strategic connections for your SEO",
        "Keyword Analysis": "Keyword Analysis",
        "Keyword analysis is the process of analyzing":
          "Keyword analysis is the process of analyzing the search volume, competitiveness, and related trends of specific words used in social media. Through this process, you can identify the popularity of specific keywords and related search terms, allowing us to improve marketing strategies and optimize content.",
        "Easy Account Setup": "Easy Account Setup",
        "Begin Your SEO Journey":
          "Begin Your SEO Journey Register with KeywordLit to start uncovering high-value search terms and phrases that will elevate your site's search rankings",
        "Keyword Discovery": "Keyword Discovery",
        "Uncover Search Gems":
          "Uncover Search Gems. Use our intuitive search feature to explore vast keyword landscapes, find niche-specific terms, and identify opportunities to outshine your competition.",
        "Campaign Integration": "Easy Account Setup",
        "Streamline Your SEO":
          "Streamline Your SEO Efforts Seamlessly integrate your keyword research into marketing campaigns, ensuring a cohesive and strategic approach to boosting your online presence",
        "Performance Analytics": "Keyword Discovery",
        "Measure Your SEO Impact":
          "Measure Your SEO Impact. Analyze your search term success with our comprehensive analytics, fine-tune your strategy, and track your progress towards SEO dominance.",
        Search: "Search",
        "Type your Keyword": "Type your Keyword",
        Instagram: "Instagram",
        Youtube: "Youtube",

        Pricing: {
          "Add Fund": "Add Fund",
          "Instagram" : "Instagram",
          "Youtube" : "Youtube",
          "related_keywords" :"15+ Related Keywords",
          "analytics_data" :"6+ Analytics Data",
          "realtime_data" :"Realtime Data",
          "accurate_data" :"100% Accurate Data",
          "per_search" : "$ 0.25/Search",
        },
        FAQ: {
          FAQ: "FAQ",
          "What does Keyword Tool do?": "What is Keywordit?",
          A1: `Keywordit is a website that provides information by analyzing keywords or hashtags used on Instagram, YouTube, and other social media platforms. Improve the direction and exposure quality of your social media content through our service.`,
          How: "Does keyword information get stored?",
          A2: `The keyword information we provide is based on real-time data. As it may vary over time, in the interest of providing accurate data, keyword analysis information is not stored.`,
          "Where does Keyword Tool get keywords from?":"Is the data updated in real-time?",
          A3: `Yes. Our data is updated in real-time to provide information.
          Popular keywords may change their analysis results depending on the search time, as the content updates quickly.`,
          "Does Keyword Tool support different languages and countries?": "How is the price for keyword search determined?",
          A4: `A fee of 250 won is incurred per "keyword search". This price includes both the searched keyword information and related keyword analysis information.
          Search result data is not stored separately.`,
        },
      },

      PrivacyPolicy: {
        privacy_policy: "Privacy Policy",
        priority_statement: `KeywordLit regards the protection of customers' personal information as a top priority and complies with relevant laws and regulations, such as the "Act on Promotion of Information and Communication Network Utilization and Information Protection" and the "Personal Information Protection Act." Through the Personal Information Handling/Processing Policy, KeywordLit informs customers of how the personal information provided by them is being used and the measures taken to protect personal information.`,
        h1: "Collection Items and Purpose of Collection and Use of Personal Information",
        h1p: "KeywordLit collects and uses the necessary minimum customer information according to the collection and use purposes through methods such as website, email, and online counseling.",

        collection_items:
          "Collection Items and Purpose of Collection and Use of Personal Information",
        membership_registration:
          "Membership Registration and Management for KeywordLit Services Collection Items",
        sb: "The collected personal information items, collection and use purposes are as follows:",

        m1: " Name, email, ID, password. [Automatically collected through service usage] Access IP address, service usage date and time,service usage records  Collection and Use Purposes",

        access_ip_address:
          "Access IP address, service usage date and time, service usage records",
        collection_and_use_purposes: "<Collection and Use Purposes>",
        member_verification:
          "Member verification, customer verification when requesting and using company services, notification of important information",
        m3: "Customer consultation and proposals: verification of identity, verification of facts, and notification of processing details",
        service_provision: "Service Provision Collection Items",
        name_billing_and_payment_records:
          "Name, billing and payment records, keyword search history Collection and Use Purposes",
        service_provision_contract:
          "Service provision contract, verification of service customers, payment of fees, electronic financial record storage for service payment, refund of transaction amount due to purchase cancellation, etc.",
        consultation:
          "Consultation Collection Items [Consultation and Proposals] Name,email, keyword search history, billing and payment records,information entered at the time of ordering, etc. Collection and Use Purposes",
        contact_and_notification:
          "Contact and notification for customer consultation/proposal, verification of facts, notification of processing details, refund of transaction amount due to purchase cancellation, etc.",

        retention_and_use_period:
          "Retention and Use Period, Method of Disposal of Personal Information.",
        internal_policy_reasons:
          "In principle, personal information is promptly disposed of after the purpose of collection and use has been achieved. However, the following information is retained for the specified periods for the reasons stated below:",

        records_related_to_contracts:
          "Records related to contracts or withdrawal of subscriptions",
        records_related_to_consumer:
          "Records related to consumer complaints or dispute resolution",
        retention_reason:
          "Retention reason: Act on Consumer Protection in Electronic Commerce",
        retention_period: "Retention period: 5 years",
        r2: "Records related to payment of fees and supply of goods, etc",
        log_records: "Log records",
        r41: "Retention reason: Protection of Communications Secrets Act",
        r42: "Retention period: 6 months",
        reasonA:
          "A. Reasons for retaining information based on internal policy Even if a member withdraws, KeywordLit may retain the member's information for up to 5 years to prevent recurrence of improper use by malicious members, for dispute resolution, and to cooperate with requests from investigative agencies.",
        reasonB:
          "B. Reasons for retaining information based on relevant laws and regulations Under the provisions of relevant laws, such as the Act on Consumer Protection in Electronic Commerce, KeywordLit retains member information for a certain period of time specified by the relevant laws, as follows:",

        provision_of_personal_information:
          "Provision of Personal Information KeywordLit does not provide personal information to third parties. However, in cases stipulated by relevant laws and regulations, KeywordLit may provide the personal information without separate consent. Personal Information Management Officer To protect customers' personal information and handle complaints related to personal information, KeywordLit has a Personal Information Management Officer. If you have any inquiries regarding personal information, please contact the following person. We will respond promptly and sincerely to your inquiries. Personal Information Management Officer and Contact Person: Name: Seo Jongwoo Email: info@keywordlit.com Method for Accessing, Correcting, Deleting, and Suspending the Processing of Personal Information",
        provision_explanation:
          "KeywordLit does not provide personal information to third parties. However, in cases stipulated by relevant laws and regulations, KeywordLit may provide the personal information without separate consent.",
        method_for_accessing:
          "Method for Accessing, Correcting, Deleting, and Suspending the Processing of Personal Information",
        customers_can_request:
          "Customers can request access, correction, deletion, or suspension of their own personal information at any time. If you wish to access, correct, delete, or suspend your personal information, please contact us through the relevant menu on the website. We will take immediate action after verifying your identity.",
        if_a_customer_requests_correction:
          "If a customer requests correction of personal information errors, we will not use or provide the information until the correction is complete. If incorrect personal information has already been provided to a third party, we will notify the result of the correction to the third party without delay to ensure that the correction is made. However, access, deletion, or suspension of personal information may be restricted in the following cases:",
        p1: "When there is a special provision in the law or when it is necessary to comply with legal obligations",
        p2: "When there is a risk of harming another person's life, body, or infringing on another person's property and other interests unjustly",
        p3: "When non-processing of personal information would make it difficult to perform a contract, and the customer has not clearly expressed an intention to terminate the contract Personal Information Collection through Cookies - Use of Cookies? KeywordLit operates 'cookies' to enhance the convenience of website visitors. Cookies are small text files that websites send to a customer's web browser and are stored on the customer's PC. KeywordLit uses cookies for the following purposes:",
        p4: "To identify the customer's primary language of use",
        p5: `To determine whether the customer has requested "Do not show again" for popup information Customers have the option to allow or refuse cookie installation, or to receive confirmation each time a cookie is stored, through web browser options. However, if a customer refuses cookie installation, there may be limitations in service provision`,

        technical_and_administrative_measures:
          "Technical and Administrative Measures for Personal Information Protection",
        technical_measures: "Technical measures ",
        administrative_measures: "Administrative measures ",
        t1: "KeywordLit takes the following technical measures to ensure the safety of customers' personal information and prevent loss, theft, leakage, alteration, or damage to personal information:",
        t2: "Personal information is protected by passwords, and important data is encrypted or protected by separate security functions.",
        t3: "KeywordLit uses antivirus programs to prevent damage caused by computer viruses. The antivirus program is regularly updated, and immediate measures are taken to apply it when sudden viruses occur to prevent infringement of personal information",
        t4: "KeywordLit adopts security devices that can securely transmit personal information over the network by using encryption algorithms.",
        t5: "KeywordLit has implemented intrusion prevention systems and server vulnerability analysis systems to prepare for hacking and other external intrusions and ensure security.",
        t6: "KeywordLit limits access to personal information to a minimum number of personnel and restricts it to the following individuals: a. Those who directly engage in marketing tasks targeting customers b. Personal information management officers and staff responsible for personal information management tasks c. Others who unavoidably handle personal information in the course of their duties",
        t7: " KeywordLit provides regular in-house and external training on security technology acquisition and personal information protection obligations for employees handling personal information.",
        t8: "Security oaths are obtained from employees handling personal information to prevent human-related information leakage in advance, and internal procedures are established to audit compliance with the personal information handling/processing policy and the responsibility of employees.",
        t9: "Obligation to Notify Policy Changes This Personal Information Handling/Processing Policy may be subject to additions, deletions, and modifications due to changes in government laws, policies, or security technologies. In such cases, we will announce the reasons and contents of the changes on the website before the revised policy takes effect.",
      },

      TermsPage: {
        heading: "Terms of Service",
        chapter: "Chapter 1 General Provisions",
        article1: "Article 1 (Purpose)",
        "11a":
          "These terms and conditions are intended to regulate the rights, obligations, and responsibilities between the company and the members in relation to the use of the J-Snet (KeywordLit) service",
        "11b": `hereinafter referred to as "J-Snet (KeywordLit)" or "Service") operated by J-Snet.`,
        article2: "Article 2 (Definitions)",
        11: `"J-Snet (KeywordLit)" refers to a virtual business place established by the company to provide social media keyword data information services (hereinafter referred to as "Service, etc.") to the members using computer and information communication facilities, and also refers to the operator of the service.`,
        12: `"Member" refers to an individual who provides personal information to J-Snet (KeywordLit) and completes the registration process, and continuously receives information from J-Snet (KeywordLit) and uses the services provided by J-Snet (KeywordLit).`,
        article3:
          "Article 3 (Specification, Explanation, and Amendment of the Terms and Conditions)",
        31: "These terms and conditions shall take effect by being posted on the service screen.",
        32: "J-Snet (KeywordLit) may modify and change these terms and conditions at any time.",
        33: "If a member does not agree to the terms and conditions, the member may request to withdraw from the membership at any time.",
        article4: "Article 4 (Membership Registration)",
        41: "Users complete the membership registration by filling out the membership information according to the registration form specified by J-Snet (KeywordLit) and expressing their agreement to these terms and conditions.",
        42: "J-Snet (KeywordLit) registers users as members who have applied to become members in accordance with paragraph 1, unless they fall under any of the following subparagraphs: a. If the applicant has previously lost membership status under Article 12, Paragraph 2 of these terms and conditions. b. If there is false, omitted, or incorrect information in the registration details. c. If it is determined that registering the member would impede the operation of J-Snet (KeywordLit) for other reasons.",
        article5: "Article 5 (Management of Member ID)",
        51: "The responsibility for managing the member ID and password lies with the user.",
        52: "The member ID may be changed or the membership may be canceled at the request of the user or at the discretion of the company under the following circumstances:",
        "5a": "a. If the ID is based on the user's phone number or resident registration number, which may pose a risk to privacy and information infringement.",
        "5b": "b. If it may be mistaken for J-Snet (KeywordLit) operator, employee, or affiliate.",
        "5c": "c. If there is a reasonable cause.",
        article6: "Article 6 (Company's Service)",
        61: "The company provides the service 24 hours a day, 365 days a year, unless there are special reasons such as business or technical difficulties. All services provided by J-Snet (KeywordLit) are available 24 hours a day.",
        62: "The company may restrict the use of the provided services and change the prices at any time.",
        63: "For the purpose of providing a better service environment, the company may partially utilize the member's network and PC resources with the consent of the member.",
        64: "The company does not guarantee that the service delivered will always be of high quality, although it strives to deliver the best service.",
        65: "The company does not promote or promise that the member will profit from using the company's services.",
        66: "In case of special reasons such as technical difficulties, the delivery of the services provided by the company may be delayed.",
        67: "The member is responsible for all liabilities that may arise from using this service.",
        68: "All deposits are made automatically. However, if this system is abused, the company may take legal action and respond to investigations.",
        article7: "Article 7 (Use of the Service)",
        71: "In accordance with the limitations specified in these terms and conditions, the company grants the user a non-exclusive and non-transferable license to access and operate the service for the period specified in the payment details.",
        72: `Each user account is granted one license. The user should make efforts to prevent the sharing of information required to access the account. Only one authentication and session maintenance is possible per account. Violation of this by attempting to unplug connections or change routes (referred to as "multiplexing" or "pooling") may result in restrictions on use`,
        73: "Even for incorrectly entered orders due to the member's negligence, the order may proceed or be completed.",
        74: "The user may use the service for appropriate business purposes and is granted limited rights and licenses to perform the contracted functions.",
        75: "If the user does not fully own the website and accesses the service indirectly or directly, the user may not frame, syndicate, distribute, reproduce, or copy any part of the user's website.",
        76: "If the user does not fully own the website and accesses the service indirectly or directly, the user may not frame, syndicate, distribute, reproduce, or copy any part of the user's website.",

        article8: "Article 8 (Order and Order Cancellation)",
        81: `Orders that have been "completed" cannot be canceled or refunded.`,
        82: "The progress of information delivery for all services may vary depending on the server status, and if it takes a long time to obtain the search information, please contact the customer center.",
        83: "Keyword search information is provided within a maximum of 2 minutes after the request is received, and the provided information is not stored separately.",
        84: `The services provided are in the form of "one-time" services. Due to the nature of the service, the provided information may change over time, and the company does not assume any responsibility for the accuracy of the data, provide refunds, or reprocess orders.`,
        85: ` Cancellation of an order is not possible if it violates the provisions in Article 7 "Precautions for Service Use."`,
        86: `Even if an order is progressing slower than expected, no refunds or cancellations will be made for orders in the "in progress" status.`,

        article9: "Article 9 (Deposit [Charge Balance])",
        91: "Members can use all services provided by J-Snet (KeywordLit) at the specified prices by charging a deposit (1 deposit = 1 USD) on the website.",
        92: "Deposits (balances) at J-Snet (KeywordLit) can be charged through the following methods:",
        "92a": "Credit card payment",
        93: "Deposits (charge balance) are made through credit card charging and are automatically charged. If automatic charging is not possible, charging can be done upon the member's request.",
        94: "If a service is purchased with a credited balance, no cash receipt or tax invoice will be issued.",
        95: "The credited balance received free of charge will be used only after all the cash (the amount actually paid by the member) has been exhausted.",
        96: `If a member acquires a balance unfairly or improperly, the member cannot use that balance, and the company may recover it.`,
        97: "The validity period of the charged deposit is 1 year from the date of the member's charge. If this period expires, the company considers that the member has waived the rights to the charged deposit. The member cannot request the reissue of the expired amount.",
        98: "The company will notify the member of the impending expiration of the deposit 30 days prior to the expiration date on the KeywordLit website screen.",

        article10: "Article 10 (Refund Policy for Balances)",
        101: "The remaining charged amount can be refunded at any time, excluding the credited amount received free of charge.",
        102: "Refunds will be processed within 1-3 business days from the date of the refund request. (If a refund request is made on Saturday, the refund will be processed from Monday to Wednesday.)",
        103: "Refunds will be automatically processed to the card used for the remaining charge amount.",
        104: "When a refund is processed, it will be refunded to the card used for the payment.",
        105: "Refunds for amounts less than 1 USD are not possible.",
        106: "For refunds of domestic credit card payments, you can contact the customer center to confirm and receive the refund. If the service has not been used after the balance is charged, a full refund is possible. If the service has been provided, a refund is possible for the remaining amount.",
        107: "(Only for users who made overseas payments) For the remaining balance, you can contact the customer center to receive a refund. Refunds through credit card chargebacks or PayPal claims for any reason are difficult.",

        article11: `Article 11 (Handling Procedures and Processing Period for Complaints
          by Members) The types of complaints, processing procedures, and
          processing periods for member complaints are as follows:`,
        111: "Type of complaint: Payment, error (malfunction), service restriction, etc.",
        112: "If a user has any complaints about the service, the user can make suggestions through the company's customer center at any time.",
        113: "The company will respond to user complaints received through the customer center as quickly as possible and provide answers and measures.",

        article12:
          "Article 12 (Membership Withdrawal and Loss of Qualification, etc.)",
        121: "A member may request to withdraw from JSNet (Keywordet) at any time, and JSNet (Keywordet) will immediately process the member's withdrawal. However, before notifying the intention to withdraw, the member must complete or cancel all ongoing transactions and bear any disadvantages caused by the cancellation or withdrawal of the transaction. In addition, if a member fails to fulfill all obligations to the company, the company may restrict the member's withdrawal until the member fulfills those obligations. If a member violates these Terms and Conditions and related laws and regulations and is suspended from using the service, the company may restrict the member's withdrawal to prevent recurrence.",
        122: "A member shall not engage in the following acts, and if such acts are committed, the company may impose sanctions, including service restrictions and legal measures, on the member:",
        "122a":
          "a. Registering false information when applying for membership or changing member information",
        "122b":
          "b. Stealing another person's ID, password, or contact information",
        "122c": "c. Trading the user ID with others",
        "122d": "d. Damaging or intentionally interfering with the service",
        "122e":
          "e. Reproducing information obtained through this service without the company's prior approval for purposes other than service use, using it for publication, broadcasting, etc., or providing it to third parties",
        "122f":
          "f. Transmitting, posting, emailing, or otherwise distributing information, sentences, figures, sounds, or videos of low morality or obscene content that violates public order and decency",
        "122g":
          "g. Transmitting, posting, emailing, or otherwise distributing content that may infringe upon the honor or privacy of others and contains offensive or personal information",
        "122h":
          "h. Engaging in objectively determined acts associated with crime",
        "122i":
          "i. Interfering with or insulting JSNet (Keywordet) members, affiliates, etc. in their work",
        "122j":
          "j. Violating other rules or terms and conditions set by the company, including these Terms and Conditions",
        "122k": "k. Not agreeing to JSNet (Keywordet)'s operational policies",
        "122l":
          "l. Engaging in acts that violate other relevant laws and regulations",
        123: "If JSNet (Keywordet) deprives a member of membership, the member's registration will be canceled. In this case, the member will be notified.",
        124: "For forcibly withdrawn members, basic member information will be retained to prevent re-registration, and the prepaid balance will not be refunded.",
        125: ` If a member violates the provisions of Article 14 "Member's Obligations"`,

        article12b: "Article 13 (Company's Obligations)",
        "12b1":
          "The company shall make its best efforts to provide services continuously, stably, and in accordance with the provisions of laws and these Terms and Conditions, without engaging in acts prohibited by laws and these Terms and Conditions.",
        "12b2":
          "The company shall not disclose or distribute customer information obtained in connection with the provision of services to third parties, nor use it for commercial purposes.",
        "12b3":
          "The company's exemption reasons for its obligations are as follows:",
        "12b3a":
          "a. As a telecommunications seller, the company only provides the system, and in the event of a dispute arising from information registered by the member or a transaction, the company does not intervene in any disputes, and all responsibilities arising from the results of such disputes lie with the member. In addition, if the company compensates third parties or incurs other costs in connection with this, the company may exercise its right of recourse against the selling member.",
        "12b3b":
          "b. The company shall be exempt from responsibility for the inability to provide services due to force majeure or events equivalent thereto, maintenance, replacement, or failure of information and communication equipment, interruption of communication, etc.",
        "12b3c":
          "c. The company shall be exempt from responsibility for damages caused by the suspension or failure to provide the telecommunications service by the telecommunications service provider.",
        "12b3d":
          "d. The company shall be exempt from responsibility for damages caused by the maintenance, replacement, regular inspection, construction, or other unavoidable reasons for the service equipment.",
        "12b3e":
          "e. The company shall not be responsible for any obstacles or damages to the use of the service or any damages caused by the member's computer errors, or damages caused by the member's inadequate provision of personal information and email address.",
        "12b3f":
          "f. The company shall not be responsible for damages caused by the data obtained by the member through the service.",
        "12b3g":
          "g. The responsibility for the inability to proceed with an order due to the delivery of impossible materials lies with the member.",
        "12b3h":
          "h. Damages incurred between members or between members and third parties through the service.",
        "12b3i":
          "i. Damages caused by the purchase or use of the company's services.",
        "12b3j": "",
        "12b4":
          "The company may restrict simultaneous access to the same account in order to protect the rights of members (service stability, account protection, prevention of malicious use, etc.).",

        article13: "Article 14 (Member's Obligations)",
        131: "When applying for membership or changing member information, the user must provide all information based on facts and real names (when requested by the company), and if false or other person's information is registered, the user cannot claim any rights and may be subject to civil and criminal penalties.",
        132: "The member must comply with the provisions of these Terms and Conditions, other rules established by the company, notices, and other matters announced by the company, as well as other laws and regulations. If the member engages in acts that hinder the company's business or damage the company's reputation, the company may claim damages and file criminal complaints against the member.",
        133: "If there are changes in contact information, email address, etc. related to the service agreement, the member must immediately notify the company of such changes.",
        134: `Except for cases where the company bears the responsibility based on related laws and the "Privacy Policy," the member shall be solely responsible for the management of the ID's password and the consequences of the negligent management and unauthorized use of the ID.`,
        135: "Without the explicit consent of the company, the member shall not transfer or donate the right to use the service or any other position in the service contract to others, nor provide it as collateral.",
        136: "The member has the obligation to manage the ID and password and shall be fully responsible for all consequences arising from the use of the member's ID and password.",
        137: "The member shall not engage in the unfair use of another member's account.",
        138: "The member shall not infringe upon the company's copyrights, third-party copyrights, and other rights.",
        139: "The member shall not transfer or share the ID and password with a third party, and the member shall be solely responsible for all consequences arising from the violation of this obligation.",
        1310: "The member shall not engage in activities that harm the company and shall be liable for damages caused by such activities. The member shall be liable to compensate the company for any damages caused by such activities.",
        1311: "Transmitting or posting information other than the information specified by JSNet (Keywordet) and transmitting or posting computer programs, etc.",
        1312: "Reproducing information obtained through the service without the prior approval of the company for purposes other than the member's use of the service, using it for publication, broadcasting, etc., or providing it to third parties.",
        1313: "Damaging JSNet (Keywordet) members, affiliates, etc. or interfering with their work.",

        article14: "Article 15 (Ownership and Restriction of Copyright)",
        141: "The copyright and other intellectual property rights to the works created by JSNet (Keywordet) belong to JSNet (Keywordet).",
        142: "The member shall not reproduce, transmit, publish, distribute, broadcast, or use for profit purposes, or allow third parties to use the information to which JSNet (Keywordet) holds intellectual property rights obtained through the use of JSNet (Keywordet) without JSNet (Keywordet)'s prior approval.",

        article15: "Article 16 (Interpretation of the Terms and Conditions)",
        151: `The "Company" may have separate service operation policies in addition to the "Terms and Conditions."`,
        152: `Matters not specified in the "Terms and Conditions" or the interpretation thereof shall be governed by service operation policies, usage instructions, notices, frequently asked questions, all pages within the site, and relevant laws and regulations. In the event of any conflict between these Terms and Conditions and any separate agreement, the separate agreement shall take precedence.`,

        article16: "Article 17 (Compensation, etc.)",
        161: "If either the company or the member violates each provision of this service agreement due to its own fault and causes damage to the other party, the damaged party may claim compensation for damages.",
        162: "If the member's fault causes the company to bear the responsibility under consumer protection laws, consumer organizations, government agencies, or other third parties, the member shall indemnify and hold the company harmless, and the member shall bear all damages and losses incurred as a result.",

        article17: "Article 18 (Governing Law and Jurisdiction)",
        171: "Matters not specified in these Terms and Conditions shall be governed by relevant laws and regulations, including the Telecommunications Business Act.",
        172: "In the event of a lawsuit arising from disputes related to the use of the service, the competent court shall be the court having jurisdiction over the location of the company's headquarters.",

        article18:
          "Article 19 (Supplementary Provisions to the Terms and Conditions)",
        181: "These Terms and Conditions shall apply together with the usage instructions provided by JSNet (Keywordet) for each individual service.",
        182: "Matters not specified in these Terms and Conditions shall be subject to applicable laws and regulations, the purpose of usage instructions, or common practices.",
      },

      Footer: {
        Email: "Email",
        footerRight:
          "Keywordlit: All information, content, and UI on the site may not be reprinted, transmitted, scraped, or otherwise used for commercial purposes without prior written consent.",
        Terms: "Terms of Service",
        Policy: "Privacy Policy",
        CompanyName: "Company name",
        CompanyNameDetail: "JS Net",
        Address: "Address",
        AddressDetail:
          "2nd floor, 15 Gyeryong-ro 105beon-gil, Yuseong-gu, Daejeon (Due to security policy, in-person consultations are not conducted.) ",
        Representative: "Representative",
        RepresentativeDetail: "Jong-woo Seo",
        PersonalInformationManager: "Personal information manager",
        PersonalInformationManagerDetail: "Jun-ho Kim",
        BusinessRegistrationNumber: "Business registration number",
        BusinessRegistrationNumberDetail: "857-14- 02508",
        EmailDetail: "info@keywordlit.com",
        CustomerCenter: "Customer Center",
        CustomerCenterDetail: " 010-6487-1736",
      },

      SignIn: {
        "Sign in": "Sign in",
        "Email Address": "Email Address",
        Password: "Password",
        "LOG IN": "LOG IN",
        "LOGGING IN": "LOGGING IN",
        "Finding Password": "Finding Password",
        "Don't have an KeywordLit account?":
          "Don't have an KeywordLit account?",
        "Sign Up": "Sign Up",
        "Enter Email Address": "Enter Email Address",
        "Enter the Password": "Enter the Password",
        "Please enter your username": "Please enter your username",
        "Enter the Password": "Enter the Password",
        Submitting: "Submitting",
      },
      Register: {
        Register: "Register",
        Name: "Name",
        "Enter Your Name": "Enter Your Name",
        "Email Address": "Email Address",
        "Enter Email Address": "Enter Email Address",
        "Enter the Password": "Enter the password",
        Password: "Password",
        "Confirm Password": "Confirm Password",
        Submit: "Submit",
        Submitting: "Submitting",
        "Agree to our": "Agree to our",
        and: "and",
        last: "",
      },
      ForgotPassword: {
        "Finding Password": "Finding Password",
        "Email Address": "Email Address",
        "Enter Email Address": "Enter Email Address",
        Submit: "Submit",
        Submitting: "Submitting",
        "Verify OTP": "Verify OTP",
        "Reset Password": "Reset Password",
        Password: "Password",
        "Confirm Password": "Confirm Password",
        "Enter password": "Enter the Password",
        "One Time Password": "One Time Password",
        "Enter OTP": "Enter OTP",
      },

      Header: {
        "Add Funds": "Add Funds",
        Settings: "Settings",
        Logout: "Logout",
        Login: "Login",
        Register: "Register",
        C: "C",
      },
      Settings: {
        "My info": "My info",
        "Deposit History": "Deposit History",
        "Search History": "Search History",
        Settings: "Settings",
        MyInfo: {
          "Account ID": "Account ID",
          Name: "Name",
          Email: "Email",
          Password: "Password",
          "Confirm Password": "Confirm Password",
          "Update Password": "Update Pssword",
          "Enter the Password": "Enter the Password",
          "Enter Email Address": "Enter Email Address",
          Submitting: "Submitting",
        },
        Deposit: {
          "Current Balance": "Current Balance",
          "Add Fund": "Add Fund",
          "Deposit ID": "Deposit ID",
          Date: "Date",
          Amount: "Amount",
          "Transaction ID": "Transaction ID",
          Method: "Method",
          Status: "Status",
        },
        Search: {
          Platform: "Platform",
          Date: "Date:",
          Keyword: "Keyword",
        },
      },
      SearchTags: {
        Search: "Search",
        "Type your keyword": "Type your keyword",
        Instagram: "Instagram",
        Youtube: "Youtube",
        hashtags: "hashtags",
        keywords: "keywords",
        "Please wait a moment while we are requesting a list of related":
          "Please wait a moment while we are requesting a list of related",
        Hashtag: "Hashtag",
        Keyword: "Keyword",
        TotalPosts: "Total Posts",
        SearchVolume: "Search Volume",
        Ranking: "Ranking",
        EstimatedCPC: "Estimated CPC",
        Competition: "Competition",
        AvgCommentsPost: "Avg. Comments/Post",
        AvgLikesPost: "Avg. Likes/Post",
        Reels: "Reels",
        AvgComments: "Avg. Comments",
        AvgLikes: "Avg. Likes",
        AvgViews: "Avg. Views",
        Top5VideoTitles: "Top 5 Video Titles",
        TotalPost: "Total Post",
        YearlyTrends: "Yearly Trends",
        MonthlyTrends: "Monthly Trends",
        WeeklyTrends: "Weekly Trends",
      },
      validation: {
        email: "Please enter your email",
        invalidE: "Invalid email",
        name: "Please enter your name",
        password: "Please enter your password",
        invalidP: "Password must have at least 8 characters",
        match: "Passwords must match",
        accept: "Please Accept.",
        "Should be atleast 5 digits": "Should be atleast 5 digits",
        "Please enter OTP recieved": "Please enter OTP recieved",
      },
      Loader: {
        Getting: "Getting Related",
        GetingMetrics: "Geting Metrics",
        AlmostThere: "Almost there",
        ItsTakingALittleLonger: "It's taking a little longer",
        Compiling: "Compiling",
        Hashtags: "Hashtags",
        Keywords: "Keywords",
      },
      CreditPage: {
        RefundPolicy: "Refund Policy",
        PolicyNum01:
          "1.You can request a refund for your remaining balance at any time within 90 days (excluding the amount received for free).",
        PolicyNum02:
          "2.Refunds will be processed within 3-5 business days from the date of your refund request. (If you request a refund on Saturday, the refund will be processed from Monday to Wednesday). ",
        PolicyNum03:
          "3.Upon request, the refund will be automatically returned to the card you used for payment, up to the remaining balance. ",
        PolicyNum04:
          "4.Refund requests are processed after confirming the email, refund amount, and name sent to mailto:info@keyword.com. ",
        PolicyNum05: "5.Amounts less than 1,000 Credit cannot be refunded. ",
        PolicyNum06:
          "6.For refunds of payments made with domestic credit cards, you can receive a refund after confirmation by contacting the customer center. If you have not used the service after topping up the balance, a full refund is possible, and if the service has been provided, a partial refund of the remaining amount is possible.",
      },
    },
  },
  ko: {
    translation: {
      Loader: {
        Getting: "관련 얻기",
        GetingMetrics: "측정항목 가져오기",
        AlmostThere: "거의 다 왔어",
        ItsTakingALittleLonger: "시간이 좀 더 걸리네요",
        Compiling: "컴파일 중",
        Hashtags: "해시태그",
        Keywords: "키워드",
      },
      validation: {
        email: "이메일을 입력해주세요",
        invalidE: "잘못된 이메일",
        name: "당신의 이름을 입력 해주세요",
        password: "비밀번호를 입력해주세요",
        invalidP: "비밀번호는 8자 이상이어야 합니다.",
        match: "비밀번호가 일치해야합니다",
        accept: "받아주세요.",
        "Should be atleast 5 digits": "5자리 이상이어야 합니다.",
        "Please enter OTP recieved": "받은 OTP를 입력해주세요",
      },
      Home: {
        "How to use": "손쉬운 이용방법",
        "Master the art": "소셜미디어 전문 키워드 분석",
        "Unlock the secret": "소셜미디어의 전문적인 키워드 데이터 분석을 통해",
        "Enhance Your Online":
          "콘텐츠 및 마케팅 전략을 효과적으로 개선할 수 있습니다.",
        "Register and add your fund": "가입 및 결제",
        "Signup easily": "간편한 회원 가입 진행 후 원하는 금액을 충전합니다.",
        "Search The Keywords": "키워드 검색",
        "Utilize our advanced search":
          "원하는 소셜미디어 플렛폼 선택 후 키워드를 입력 및 검색합니다.",
        "Analysis the Data": "키워드 데이터 분석 및 활용",
        "Leverage our detailed":
          "검색한 키워드의 상세한 데이터 및 관련키워드 확인 후, 비교 및 활용해 보세요",
        "Visualizing the Complexity of Keywords": "키워드 분석이란?",
        "Explore the intricate network of keyword":
          "키워드 분석은 특정 단어나 구문의 검색량, 경쟁 정도, 관련 트렌드 등을 조사하는 프로세스로, 소셜 미디어와 검색 엔진에서 주로 이용됩니다. 이 과정을 통해 특정 키워드의 동향과 관련 검색어를 파악하고 활용하면 마케팅 전략을 발전시키고 콘텐츠를 최적화하는 데 도움이 됩니다.",
        "Keyword Analysis": "키워드 정보 활용",
        "Keyword analysis is the process of analyzing":
          "키워드는 마케팅 전략에서 중요한 역할을 합니다. 소셜미디어에서 키워드를 적절히 활용하여 검색 엔진에서 노출 및 알고리즘에 노출 되어 사용자들의 관심을 끌 수 있습니다. 키워드를 콘텐츠 제목, 설명, 태그 등에 적절히 포함시켜 검색 엔진 최적화(SEO)를 향상시키고, 타겟 대상의 관심사에 부합하는 콘텐츠를 제공할 수 있습니다. 이를 통해 콘텐츠의 유입량과 사용자 참여를 증가시킬 수 있습니다.",
        "Easy Account Setup": "트랜드 파악",
        "Begin Your SEO Journey":
          "실시간으로 변화하는 트렌드와 사용자들의 관심사를 신속하게 파악할 수 있습니다. 키워드 트렌드에 맞는 콘텐츠 및 시장 동향을 파악하고 경쟁력을 강화하는 데 도움이 됩니다.",
        "Keyword Discovery": "타겟마케팅",
        "Uncover Search Gems":
          "키워드 정보와 활동 내역을 분석하여 타겟 마케팅에 활용할 수 있습니다. 특정 키워드의  최근 정보를 식별하여 본인에 맞는 키워드를 사용함으로써 효과적인 마케팅 전략을 구축할 수 있습니다.",
        "Campaign Integration": "관련 키워드 정보",
        "Streamline Your SEO":
          "특정 키워드와 관련된 키워드를 추천해줄 수 있습니다. 이를 통해 해당키워드의 관심사를 파악하고, 새로운 키워드를 발견하여 마케팅 전략에 활용할 수 있습니다.",
        "Performance Analytics": "자세한 키워드 정보",
        "Measure Your SEO Impact":
          "전문적인 키워드 분석을 한 눈에 볼 수 있습니다. 자세한 정보를 통하여 키워드 활용에 편하며 또한, 키워드 정보 수집하여 콘텐츠 방향 및 노출 향상에 활용할 수 있습니다.",
        Search: "검색",
        "Type your Keyword": "키워드를 입력하세요",
        Instagram: "인스타그램",
        Youtube: "유튜브",

        Pricing: {
          "Add Fund": "충전하기",
          "Instagram" : "인스타그램",
          "Youtube" : "유튜브",
          "related_keywords" :"15+ 관련 키워드",
          "analytics_data" :"6+ 데이터 분석 결과",
          "realtime_data" :"실시간 데이터 결과",
          "accurate_data" :"100% 정확한 데이터",
          "per_search" : "250원/검색",
        },
        FAQ: {
          FAQ: "자주하는 질문",
          "What does Keyword Tool do?": "키워드릿은 무엇인가요?",
          A1: `키워드릿은 인스타그램, 유튜브, 소셜 미디어 등에서 사용되는 키워드 또는 해시태그를 분석하여 정보를 제공하는 웹사이트입니다. 저희 서비스를 통해 소셜 미디어 컨텐츠의 방향성과 노출 퀄리티를 높여보세요.`,
          How: "키워드 정보는 저장이 되나요?",
          A2: `저희가 제공하는 키워드 정보는 실시간 데이터를 바탕으로 제공되는 정보입니다. 그러기에 시간이 변함에 따로 변동이 있을 수 있어, 정확한 데이터를 제공하는 취지에서 키워드 분석 정보는 저장되지 않습니다.`,
          "Where does Keyword Tool get keywords from?":"데이터는 실시간으로 업데이트 되나요?",
          A3: `네. 저희 데이터는 실시간으로 업데이트 되어 정보를 제공하고 있습니다.
          * 인기 키워드는 컨텐츠가 빠르게 업데이트 되기 때문에 검색 시점에 따라 분석 결과가 바뀔수도 있습니다.`,
          "Does Keyword Tool support different languages and countries?": "키워드 검색 가격은 어떻게 측정 되나요?",
          A4: `키워드 검색 정보는 1회 “키워드 검색”당 250원의 비용이 발생합니다.
          이 가격은 검색한 키워드 정보와 관련 키워드 분석 정보가 모두 포함된 가격입니다.
          * 검색 결과 데이터는 따로 저장되지 않습니다. `,
        
        },
      },

      PrivacyPolicy: {
        privacy_policy: "개인정보 처리 방침",
        priority_statement01:
          "키워드릿은 고객의 개인정보보호를 매우 중요시 하며, “정보통신망이용 촉진 및 정보보호 등에 관한 법률” 및 “개인정보 보호법” 등 관련 법률과 규정을 준수하고 있습니다.",
        priority_statement02:
          "키워드릿은 개인정보취급/처리방침을 통하여 고객께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.",
        collection_items: "개인정보 수집 및 이용 목적 및 수집 항목",
        membership_registration: "키워드릿 서비스 회원 가입 및 관리 수집 항목",
        h1: "개인정보 수집 항목 및 수집 및 이용 목적.",
        h1p: "키워드릿은 웹 사이트, 이메일 및 온라인 상담과 같은 방법을 통해 수집 및 이용 목적에 따라 필요한 최소한의 고객 정보를 수집하고 사용합니다.",
        sb: "수집된 개인정보 항목 및 수집 및 이용 목적은 다음과 같습니다:",
        m1: "이름, 이메일, ID, 비밀번호. [서비스 이용 중 자동 수집] 접속 IP 주소, 서비스 이용 날짜 및 시간, 서비스 이용 기록 수집 및 이용 목적",
        m3: "고객 상담 및 제안: 신원 확인, 사실 확인 및 처리 세부 정보 통보",
        access_ip_address:
          "접속 IP 주소, 서비스 이용 날짜 및 시간, 서비스 이용 기록",
        collection_and_use_purposes: "<수집 및 이용 목적>",
        member_verification:
          "회원 확인, 회사 서비스 요청 및 사용 시 고객 확인, 중요 정보 통지",
        service_provision: "서비스 제공 수집 항목",
        name_billing_and_payment_records:
          "이름, 청구 및 결제 기록, 키워드 검색 기록 수집 및 이용 목적",
        service_provision_contract:
          "서비스 제공 계약, 서비스 고객 확인, 수수료 지불, 전자 금융 기록 저장, 구매 취소로 인한 거래 금액 환불 등",
        consultation:
          "상담 수집 항목 [상담 및 제안] 이름, 이메일, 키워드 검색 기록, 청구 및 결제 기록, 주문 시 입력한 정보 등 수집 및 이용 목적",
        contact_and_notification:
          "고객 상담/제안 연락 및 통지, 사실 확인, 처리 내용 통지, 구매 취소로 인한 거래 금액 환불 등",
        retention_and_use_period: "개인정보 보유 및 이용 기간, 폐기 방법",
        internal_policy_reasons:
          "개인정보는 수집 및 이용 목적 달성 후 즉시 폐기됩니다. 그러나 다음 정보는 명시된 기간 동안 보존됩니다.",
        records_related_to_contracts: "계약 또는 가입 해지와 관련된 기록",
        records_related_to_consumer: "소비자 불만 또는 분쟁 해결과 관련된 기록",
        retention_reason:
          "보존 이유: 전자상거래 등에서의 소비자 보호에 관한 법률",
        retention_period: "보존 기간: 5년",
        r2: "수수료 지불 및 상품 공급과 관련된 기록 등",
        reasonA:
          "내부 정책에 따른 정보 보유 사유 회원이 탈퇴하더라도 키워드릿은 악의적 회원에 의한 부적절한 사용의 재발을 방지하고 분쟁 해결을 위해 회원 정보를 최대 5년간 보유할 수 있으며, 수사 기관의 요청에 협력할 수 있습니다.",
        reasonB:
          "관련 법령에 따른 정보 보유 사유 전자상거래 등에서의 소비자 보호에 관한 법률 등 관련 법령의 규정에 따라 키워드릿은 관련 법령에서 명시한 일정 기간 동안 회원 정보를 보유합니다:",
        log_records: "로그 기록",
        r41: "보존 이유: 통신비밀보호법",
        r42: "보존 기간: 6개월",
        provision_of_personal_information:
          "개인정보 제공 키워드릿은 개인정보를 제3자에게 제공하지 않습니다. 그러나 관련 법률 및 규정에서 정한 경우 별도의 동의 없이 개인정보를 제공할 수 있습니다. 고객의 개인정보를 보호하고 관련 불만을 처리하기 위해 키워드릿은 개인정보 관리 책임자를 운영합니다. 개인정보 관련 문의사항이 있으시면 아래 담당자에게 연락해 주십시오. 귀하의 문의에 신속하고 성실하게 대응하겠습니다. 개인정보 관리 책임자 및 연락처: 이름: 서종우 이메일: info@keywordlit.com 개인정보 열람, 정정, 삭제 및 처리 일시 중지 방법",
        provision_explanation:
          "키워드릿은 개인정보를 제3자에게 제공하지 않습니다. 그러나 관련 법률 및 규정에서 정한 경우 별도의 동의 없이 개인정보를 제공할 수 있습니다.",
        method_for_accessing:
          "개인정보 열람, 정정, 삭제 및 처리 일시 중지 방법",
        customers_can_request:
          "고객은 언제든지 자신의 개인정보에 대한 열람, 정정, 삭제 또는 처리 일시 중지를 요청할 수 있습니다. 개인정보 열람, 정정, 삭제 또는 처리 일시 중지를 원하시는 경우 웹 사이트의 관련 메뉴를 통해 연락해 주시기 바랍니다. 신원 확인 후 즉시 조치를 취하겠습니다.",
        if_a_customer_requests_correction:
          "고객이 개인정보 오류 정정을 요청한 경우 정정 완료 전까지 해당 정보를 사용하거나 제공하지 않습니다. 이미 잘못된 개인정보가 제3자에게 제공된 경우 정정 결과를 즉시 해당 제3자에게 통지하여 정정이 이루어지도록 보장합니다. 그러나 다음 경우에는 개인정보의 열람, 삭제 또는 처리 일시 중지가 제한될 수 있습니다:",
        p1: "법령에 특별한 규정이 있거나 법적 의무를 준수해야 하는 경우",
        p2: "다른 사람의 생명, 신체, 재산 또는 그 밖의 이익을 부당하게 침해할 우려가 있는 경우",
        p3: "계약 이행이 어려워지고 고객이 명확하게 계약 해지 의사를 표명하지 않은 경우",
        p4: "쿠키를 통한 개인정보 수집 - 쿠키 사용?",
        p5: "키워드릿은 웹사이트 방문자의 편의를 높이기 위해 '쿠키'를 사용합니다. 쿠키는 웹 사이트가 고객의 웹 브라우저로 전송하고 고객의 PC에 저장하는 작은 텍스트 파일입니다. 키워드릿은 다음과 같은 목적으로 쿠키를 사용합니다:",
        technical_and_administrative_measures:
          "개인정보 보호를 위한 기술 및 관리적 조치",
        technical_measures: "기술적 조치",
        administrative_measures: "관리적 조치",
        t1: "키워드릿은 고객의 개인정보 안전을 보장하고 개인정보의 손실, 도난, 유출, 변경 또는 훼손을 방지하기 위해 다음과 같은 기술적 조치를 취합니다:",
        t2: "개인정보는 비밀번호로 보호되며 중요한 데이터는 암호화되거나 별도의 보안 기능으로 보호됩니다.",
        t3: "키워드릿은 컴퓨터 바이러스에 의한 피해를 방지하기 위해 백신 프로그램을 사용합니다. 백신 프로그램은 정기적으로 업데이트되며 갑작스러운 바이러스 발생 시 즉시 적용되어 개인정보 침해를 방지합니다.",
        t4: "키워드릿은 암호화 알고리즘을 사용하여 네트워크를 통해 개인정보를 안전하게 전송할 수 있는 보안 장치를 채택하였습니다.",
        t5: "키워드릿은 해킹 및 기타 외부 침입에 대비하여 침입 방지 시스템과 서버 취약성 분석 시스템을 구축하여 보안을 유지합니다.",
        t6: "키워드릿은 최소한의 인원에게 개인정보 접근을 제한하고 다음과 같은 인원에게만 제한하여 처리합니다: a. 고객을 대상으로 직접 마케팅 작업을 수행하는 자 b. 개인정보 관리 책임자 및 개인정보 관리 업무를 담당하는 직원 c. 직무 수행 중 개인정보를 부득이하게 처리하는 기타 인원",
        t7: "키워드릿은 개인정보를 처리하는 직원에 대해 보안 기술 습득 및 개인정보 보호 의무에 대한 정기적인 내부 및 외부 교육을 제공합니다.",
        t8: "개인정보를 처리하는 직원으로부터 인적인 정보 유출을 사전에 방지하기 위해 보안 서약을 받고 개인정보 처리/운용 정책 및 직원의 책임 준수를 감사하기 위한 내부 절차를 마련하였습니다.",
        t9: "정책 변경 통지 의무 이 개인정보 처리/운용 정책은 정부의 법률, 정책 또는 보안 기술의 변경으로 인해 추가, 삭제 및 수정될 수 있습니다. 이러한 경우 수정된 정책이 효력을 발생하기 전에 웹 사이트에 변경 이유와 내용을 공지할 것입니다.",
      },

      TermsPage: {
        heading: "서비스 약관",
        chapter: "제1조 (목적)",
        article1: "제1조(목적)",
        11: "이 약관은 제이에스넷(키워드릿)가 운영하는 제이에스넷(키워드릿) 서비스(https://www.keywordlit.com, 이하 ‘제이에스넷(키워드릿)’ 또는 ‘서비스’라 합니다)를 이용함에 있어 회사와 회원간의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.",
        article2: "제2조 (정의)",
        "11a":
          "‘제이에스넷(키워드릿)’란 회사가 소셜미디어 키워드 데이터 정보 제공 서비스 (이하 “서비스 등”이라 함)를 회원에게 제공하기 위하여 컴퓨터등 정보통신설비를 이용하여 서비스를 구매 및 이용 수 있도록 설정한 가상의 영업장을 말하며, 아울러 서비스를 운영하는 사업자의 의미로도 사용합니다.",
        "11b":
          "‘회원’이란 ‘제이에스넷(키워드릿)’에 개인정보를 제공하여 가입등록을 한 자로서, ‘제이에스넷(키워드릿)’의 정보를 지속적으로 제공받으며, 제이에스넷(키워드릿)가 제공하는 서비스를 계속적으로 이용할 수 있는 자나 회사를 말합니다.",

        article3: "제3조 (약관의 명시와 설명 및 개정)",
        31: "본 약관은 서비스 화면의 게시를 통하여 공지함으로써 효력이 발생됩니다.",
        32: ". 회원은 약관에 동의하지 않을 경우 자유로운 권리로 언제든지 회원탈퇴를 요청할 수 있습니다.",

        article4: "제4조 (회원가입)",
        41: "이용자는 제이에스넷(키워드릿)가 정한 가입 양식에 따라 회원정보를 기입한 후 본 약관에 동의한다는 의사표시를 함으로서 회원가입을 완료합니다.",
        42: "제이에스넷(키워드릿)는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각호에 해당하지 않는 한 회원으로 등록합니다.",
        "42a":
          "가입신청자가 이 약관 제12조제2항에 의하여 이전에 회원자격을 상실한 적이 있는 경우",
        "42b": "등록 내용에 허위, 기재누락, 오기가 있는 경우",
        "42c":
          "기타, 회원으로 등록하는 것이 제이에스넷(키워드릿)의 운영상 지장이 있다고 판단되는 경우",

        article5: "제5조 (회원아이디 관리)",
        51: "회원아이디 및 비밀번호에 대한 관리책임은 이용자에게 있습니다.",
        52: "회원아이디는 다음의 경우에 한하여 이용자의 요청 또는 회사의 판단에 의하여 변경 및 회원탈퇴할 수 있습니다.",
        "5a": "가. 아이디가 회원의 전화번호나 주민등록번호등으로 되어 있어서 사생활 및 정보침해의 우려가 있는 경우.",
        "5b": "나. 제이에스넷(키워드릿) 운영자, 직원 또는 관계자로 오인할 수 있는 경우.",
        "5c": "다. 상식적으로 합리적인 사유가 있는 경우.",
        "5d": "라. 부정적인 서비스 이용 및 서비스 오류 제공",

        article6: "제6조 (회사의 서비스)",
        61: "본 서비스는 회사의 업무, 기술상의 장애등 특별한 사유가 없는 한 연중무휴  365일 24시간 서비스를 제공합니다. '제이에스넷(키워드릿)'가 제공하는 모든 서비스는 24시간 언제든지 이용이 가능합니다.",
        62: "회사는 언제든지 제공하는 서비스의 이용을 제한하고 가격변경을 할 수 있습니다.",
        63: "본 서비스 사용에 동의한 회원에 한하여 더 나은 서비스 사용환경 제공을 목적으로 회원의 네트워크 및 PC 자원을 부분적으로 활용할 수 있습니다.",
        64: "회사는 최고의 서비스를 전달하도록 노력하지만, 전달하는 서비스가 항상 고품질로 진행되어지는것을 보장하지 않습니다.",
        65: "회사는 회원이 회사의 서비스를 이용하여 수익을 얻을 수 있다고 홍보 및 약속 하지 않습니다.",
        66: `기술상의 장애등 특별한 사유가 있을 경우, 회사가 제공하는 서비스의 주문이행은 다소 "지연"이 될 수 있습니다.`,
        67: "본 서비스를 이용함으로서 일어날 수 있는 모든 책임은 회원에게 있습니다.",
        68: "모든 입금은 자동으로 이루어집니다. 단, 이러한 시스템이 악용 될 경우 회사는 고발 및 고소를 취할수있으며 수사요청에 응할수있습니다.",

        article7: "제7조 (서비스의 이용)",
        71: "이 약관에 포함되는 제한 사항에 따라 회사는 이용자에게 서비스에 접근하고 운영할 수 있도록 결제 내역에 명시된 기간 동안 비독점적이고 양도 불가능한 라이선스를 부여합니다.",
        72: `이용자 계정당 하나의 라이선스가 부여됩니다. 이용자는 해당 계정에 접근하기위한 정보가 공유되지 않도록 노력하여야 합니다. 하나의 계정당 하나의 인증 및 세션 유지가 가능합니다. 이용자는 이 라이선스를 줄이기 위해 연결을 풀거나 경로를 변경하는 행위 ("멀티플렉싱" 또는 "풀링" 이라고 함)를 하지 않고 이를 위반시 이용에 제한이 있을 수 있습니다.`,
        73: `회원의 과실로 인해 잘못 입력된 주문에 대해서도 주문은 진행 또는 완료 될 수 있습니다.`,
        74: "이용자는 적합한 업무 용도로만 서비스를 이용하며, 계약된 기능을 수행할 수 있는 제한된 권리와 라이선스가 부여 됩니다.",
        75: "이용자가 온전히 소유한 이용자의 웹사이트 경우가 아닌 경우, 본 서비스에 직⠂간접적으로 접근하기 위해 이용자의 웹사이트의 일부를 구성(frame), 신디케이트, 배포, 복제, 및 복사할 수 없습니다.",
        76: "이용자가 온전히 소유한 이용자의 웹사이트 경우가 아닌 경우, 본 서비스에 직⠂간접적으로 접근하기 위해 이용자의 웹사이트의 일부를 구성(frame), 신디케이트, 배포, 복제, 및 복사할 수 없습니다.",

        article8: "제8조 (주문 및 주문취소)",
        81: `"검색이 완료"된 주문에 대해서는 주문 취소, 환불이 불가능 합니다.`,
        82: "시스템에 전달된 정보는 순차적으로 진행이되며 모든 서비스의 정보제공 속도는 서버 상태에 따라 모두 다르기 때문에 예측을 할 수 없으며,  검색 정보를 얻는데 시간이 많이 소요되면  고객센터로 연락 바랍니다",
        83: "키워드 검색이  접수가 되면 최대 2분이내에 정보가 제공되며, 해당 정보는 따로 저장하지 않고 있습니다",
        84: `제공되어지는 서비스는 "일회형" 형식의 서비스 입니다. 서비스의 특성상 제공 되는 정보는 시간이 지난면 변할수도 있으며, 정확한 수치에 대하여 회사는 책임을 지거나, 환불을 하거나, 주문에 대한 작업을 재진행하지 않습니다.`,
        85: "제7조 '서비스이용의 주의사항'에서 규정한 사항에 위배되는 행위를 한 경우 주문취소가 불가능 합니다",
        86: "회사는 주문이 기대에 비해 느리게 진행되고 있다고 하더라도 “진행중” 상태인 주문에 대해서는 환불 및 주문취소를 하지 않습니다",

        article9: "",
        91: "회원은 사이트에 예치금(1예치금 = 1원)를 충전함으로써 제이에스넷(키워드릿)의 모든 서비스를 기재된 가격으로 이용할 수 있습니다.",
        92: "제이에스넷(키워드릿)에서의 예치금(잔액)충전은 다음을 통하여 할 수 있습니다.",
        "92a": "카드결제 ",
        93: "예치금(충전잔액)은 카드충전로 이루어지며 자동 충전되어집니다. 자동 충전이 안될시 회원의 요청으로 충전이 될수있습니다.",
        94: "무상으로 적립된 잔액을 통해 서비스를 구매한 경우, 현금영수증 또는 세금계산서가 발행 되지 않습니다.",
        95: "무상으로 지급받은 잔액은 유상으로 충전한 캐시(실제로 회원이 결제한 금액)를 모두 소진한 이후부터 사용되어 집니다.",
        96: "회원이 부당 또는 부정하게 잔액을 취득한 경우 회원은 해당 잔액을 사용할 수 없으며 회사는 이를 회수할 수 있습니다.",
        97: "충전된 예치금의 유효기간은 회원이 충전한 기한 날로부터 1년입니다. 해당 기간이 지날경우 회사는 회원이 충전된 예치금의 대한 권리를 포기하는 것으로 간주합니다. 회원은 소멸된 금액을 재청구 할 수 없습니다.",
        98: "회사는 회원에게 예치금 소멸일 30일 전에 소멸 예정 사실을 키워드릿 사이트내 화면에 통지하며 소멸된 예치금은 회사에 귀속됩니다.",

        article10: "제10조 (잔액의 환불규정)",
        101: "남은 충전금에 대해 언제든지 환불 신청을 할 수 있습니다. (무상으로 적립 받은 금액은 제외).",
        102: "환불은 환불요청 주신 날짜로부터 업무일 1~3일 안에진행됩니다. (만일 토요일에 환불을 진행요청을 주신 경우, 월요일~ 수요일까지 환불진행을 해드립니다.",
        103: "환불은 신청시 회원이 결제한 카드로 남은 충전금액만큼 자동 환불되어집니다.",
        104: "환불이 진행되면 회원은 환불신청시 결제하신 카드로 환불이 진행됩니다.. ",
        105: "1,000원 미만의 금액은 환불이 불가합니다.",
        106: "국내 신용카드의 결제 환불의 경우 고객센터로 연락을 주시면 확인 후 환불을 받으실 수 있습니다. 잔액 충전 후 서비스를 이용 안하셨을 경우엔 전체환불, 서비스 제공이 완료된 경우 남은 금액 부분 환불이 가능합니다.",
        107: "(해외결제 유저들에 대해서만 해당) 남은 잔액에 대해서는 고객센터로 연락을 주시면 환불을 받으실수 있습니다. 어떠한 이유로도 카드사 차지백 또는 페이팔 클레임을 통해 환불이 어렵습니다.",

        article11:
          "제11조 (회원 불만형태별 처리절차 및 처리기간) 이용자 불만형태와 처리절차 및 처리기간은 아래와 같습니다.",
        111: "불만형태 : 결제, 오류(장애), 서비스 제한 등.        ",
        112: "이용자는 서비스에 불만이 있을 시, 회사의 고객센터를 이용하여 언제든지 건의할 수 있습니다.        ",
        113: "회사는 고객센터의 이용자 불만사항에 대하여 최대한 빠른 시일내에 답변 및 조치사항을 회신합니다.        ",
        114: "",
        115: "",
        116: "",

        article12: "제12조 (회원 탈퇴 및 자격 상실 등)",
        121: "회원은 제이에스넷(키워드릿)에 언제든지 탈퇴를 요청할 수 있으며 제이에스넷(키워드릿)는 즉시 회원탈퇴를 처리합니다. 단, 탈퇴의사를 통지하기 전에 현재 진행 중인 모든 거래를 완료하거나 철회 또는 취소하여야 하며, 거래의 철회 또는 취소로 인한 불이익은 회원 본인이 부담하여야 합니다. 또한, 회원이 회사에 대한 채무를 전부 이행하지 않으면 회원이 해당 채무를 전부 이행할 때까지 회사는 회원의 탈퇴를 제한할 수 있으며, 회원이 이 약관과 관계 법령 등을 위반하여 이용이 정지된 경우 회사가 재발방지를 위하여 회원의 탈퇴를 제한할 수 있습니다.",
        122: "회원은 다음 각 호에 해당하는 행위를 하여서는 안되며, 해당 행위를 하는 경우에 회사는 회원의 서비스 이용제한 및 법적 조치를 포함한 제재를 가할 수 있습니다.",
        "122a":
          "가. 회원가입 신청 또는 회원정보 변경 시 허위내용을 등록하는 행위",
        "122b": "나. 타인의 ID, 비밀번호, 연락처를 도용하는 행위",
        "122c": "다. 이용자 ID를 타인과 거래하는 행위",
        "122d": "라. 서비스에 위해를 가하거나 고의로 방해하는 행위",
        "122e":
          "마. 본 서비스를 통해 얻은 정보를 회사의 사전 승낙 없이 서비스 이용 외의 목적으로 복제하거나, 이를 출판 및 방송 등에 사용하거나, 제 3자에게 제공하는 행위",
        "122f":
          "바. 공공질서 및 미풍양속에 위반되는 저속, 음란한 내용의 정보, 문장, 도형, 음향, 동영상을 전송, 게시, 전자우편 또는 기타의 방법으로 타인에게 유포하는 행위",
        "122g":
          "사. 모욕적이거나 개인신상에 대한 내용이어서 타인의 명예나 프라이버시를 침해할 수 있는 내용을 전송, 게시, 전자우편 또는 기타의 방법으로 타인에게 유포하는 행위",
        "122h": "아. 범죄와 결부된다고 객관적으로 판단되는 행위",
        "122i":
          "자. 제이에스넷(키워드릿) 구성원, 협력사 등에 대한 업무방해 또는 욕설 등을 하는 경우",
        "122j":
          "차. 본 약관을 포함하여 기타 회사가 정한 제반 규정 또는 이용 조건을 위반하는 행위",
        "122k": "카. 제이에스넷(키워드릿)의 운영 정책에 동의하지 않는 경우",
        "122l": "타. 기타 관계법령에 위배되는 행위",
        123: "제이에스넷(키워드릿)가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지합니다.",
        124: "강제 탈퇴가 된 회원에 한해 재가입 방지를 위해 기본적인 회원정보가 보관되며, 충전금(잔고)는 환불되지 않습니다.",
        125: "제 14 조 회원의 의무'에서 규정한 사항에 위배되는 행위를 한 경우",

        article12b: "제12조 (회사의 의무)",
        "12b1":
          "회사는 법령과 이 약관이 금지하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를 제공하는데 최선을 다하여야 합니다.",
        "12b2":
          "회사는 서비스제공과 관련하여 취득한 이용고객의 정보를 타인에게 누설 또는 배포 할 수 없으며 상업적 목적으로 사용할 수 없습니다",
        "12b3": "회사의 의무에 대한 면책사유는 다음과 같습니다.",
        "12b3a":
          "가. 회사는 통신판매자로서 시스템만 제공할 뿐, 회원이 등록한 정보 또는 거래에 관하여 분쟁이 발생한 경우 회사는 그 어떠한 분쟁에도 개입하지 않으며 그 분쟁의 결과로 인한 모든 책임은 회원이 부담합니다. 또한 이와 관련하여 회사가 제3자에게 손해를 배상하거나 기타 비용을 지출한 경우 회사는 판매회원에게 구상권을 행사할 수 있습니다.",
        "12b3b":
          "나. 회사는 천재지변 또는 이에 준하는 불가항력, 정보통신설비의 보수점검, 교체 또는 고장, 통신의 두절 등으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.",
        "12b3c":
          "다. 회사는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.",
        "12b3d":
          "라. 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.",
        "12b3e":
          "마. 회사는 회원의 귀책사유로 인한 서비스 이용의 장애 또는 손해에 대하여 책임을 지지 않습니다.",
        "12b3f":
          "바. 회사는 이용자의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 회원이 신상정보 및 전자우편 주소를 부실하게 기재하여 손해가 발생한 경우 책임을 지지 않습니다.",
        "12b3g":
          "사. 회사는 회원이 서비스를 이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지 않습니다.",
        "12b3h":
          "아. 회원이 서비스 진행에 있어 주문진행이 불가능한 자료 전달에 대한 책임.",
        "12b3i":
          "자. 회원 상호간 또는 회원과 제3자 상호간의 서비스를 매개로 하여 발생된 손해.",
        "12b3j": "차. 회사의 서비스구매 또는 이용으로 인해 발생되는 손해.",
        "12b4":
          "회사는 회원 권리(서비스 안정성, 계정보호 및 악의적 이용 방지 등) 보호를 위해 동일계정의 동시접속을 제한할 수 있습니다.",

        article13: "제13조 (회원의 의무).",
        131: "이용자는 회원가입 신청 또는 회원정보 변경 시 모든 사항을 사실과 실명(회사의 요청시)에 근거하여 작성하여야 하며, 허위 또는 타인의 정보를 등록할 경우 일체의 권리를 주장할수 없으며 그에따른 민형사상 책임 처벌을 받아야 합니다.",
        132: "회원은 본 약관에서 규정하는 사항과 기타 회사가 정한 제반 규정, 공지사항 등 회사가 공지하는 사항 및 관계법령을 준수하여야 하며, 기타회사의 업무에 방해가 되는 행위, 회사의 명예를 손상시키는 행위로 회사가 피해를 입는 경우 회사가 회원에게 손해배상 및 형사고소를 취할수 있습니다.",
        133: "회원은 연락처, 이메일 주소 등 이용계약사항이 변경된 경우에 해당 절차를 거쳐 이를 회사에 즉시 알려야 합니다.",
        134: "회사가 관계법령 및 '개인정보 취급방침'에 의거하여 그 책임을 지는경우를 제외하고 회원에게 부여된 ID의 비밀번호 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있으며 이에따른 피해는 제이에스넷(키워드릿)에게 청구 할수 없습니다.",
        135: "회원은 회사의 명시적 동의가 없는 한 서비스의 이용권한, 기타 이용계약상의 지위를 타인에게 양도, 증여할 수 없으며 이를 담보로 제공할 수 없습니다.",
        136: "회원은 아이디와 비밀번호를 관리할 의무를 가지며 회원의 아이디와 비밀번호를 사용하여 발생하는 모든 결과에 대한 책임은 전적으로 회원에게 있습니다.",
        137: "다른 회원의 계정을 부당하게 사용하는 행위를 하지 않아야 합니다.",
        138: "회사의 저작권, 제3자의 저작권등 기타 권리를 침해하는 행위는 하지 않아야 합니다.",
        139: "회원은 아이디와 비밀번호를 제3자에게 양도 공유할 수 없으며, 이를 위반하여 발생되는 모든 결과에 대한 책임은 전적으로 회원에게 있습니다.",
        1310: "회원은 회사에 위해를 가하는 활동을 할수 없으며, 해당활동의 결과로 인하여 발생된 책임에 대하여 회사는 책임지지 않습니다. 회원은 이와 같은 활동에 대하여 회사에 대한 손해배상 의무를 집니다.",
        1311: "제이에스넷(키워드릿)가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시",
        1312: "서비스에서 얻은 정보를 회사의 사전승낙 없이 회원의 이용 이외의 목적으로 복제하거나 이를 출판 및 방송 등에 사용하거나 제3자에게 제공하는 행위를 하지 않아야 합니다.",
        1313: "제이에스넷(키워드릿) 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위",

        article14: "제14조 (저작권의 귀속 및 이용제한)",
        141: "제이에스넷(키워드릿)가 작성한 저작물에 대한 저작권 기타 지적재산권은 제이에스넷(키워드릿)에 귀속합니다.",
        142: "회원은 제이에스넷(키워드릿)를 이용함으로써 얻은 정보 중 제이에스넷(키워드릿)에게 지적재산권이 귀속된 정보를 제이에스넷(키워드릿)의 사전 승낙없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.",
        143: "",
        144: "",
        145: "",
        146: "",
        article15: "제15조 (약관의 해석)",
        151: "“회사”는 “약관” 외에 별도의 서비스운영정책 등을 둘 수 있습니다.",
        152: "“약관”에서 정하지 않은 사항이나 해석에 대하여는 서비스운영정책, 이용안내, 공지사항, 자주묻는질문, 사이트내 모든 페이지, 관련 법령에 따르고, 본 약관과 다른 별도 합의가 있는 경우 별도 합의가 우선적용됩니다.",
        153: "",
        154: "",
        155: "",
        156: "",
        article16: "제16조 (손해배상 등)",
        161: "회사와 회원은 일방의 귀책사유로 인하여 본 이용계약의 각 조항을 위반함으로써 상대방이 손해를 입었을 때에는 손해배상을 청구할 수 있습니다.",
        162: "회원의 귀책사유로 인하여 회사가 소비자, 소비자단체, 관공서, 기타 제3자로부터 손해배상의 청구, 제소, 행정조치, 기타 법률상의 책임을 부담하게 될 경우 회원은 회사를 방어하고 면책시키며 일체의 피해, 손실에 대하여 배상책임을 집니다.",
        163: "",
        164: "",
        165: "",
        166: "",
        article17: "제17조 (준거법 및 재판관할)",
        171: "이 약관에 명시되지 않은 사항은 전기통신사업법 등 관계법령과 상관습에 따릅니다.",
        172: "서비스 이용으로 발생한 분쟁에 대해 소송이 제기되는 경우 회사의 본사 소재지를 관할하는 법원을 관할 법원으로 합니다.",
        173: "",
        174: "",
        175: "",
        176: "",
        article18: "제18조 (약관 외 준칙)",
        181: "이 약관은 제이에스넷(키워드릿)에서 제공하는 개별서비스에 관한 이용안내와 함께 적용합니다.",
        182: "이 약관에 명시되지 아니한 사항에 대해서는 관계법령 및 이용안내의 취지 또는 통상적인 관습에 따라 적용할 수 있습니다.",
      },

      Footer: {
        Email: "이메일",
        footerRight:
          " 키워드릿 사전 서면 동의 없이 사이트의 일체의 정보, 콘텐츠 및 UI 등을 상업적 목적으로 전재, 전송, 스크래핑 등 무단 사용할 수 없습니다.",
        Terms: "서비스 약관",
        Policy: "개인정보 취급/처리방침",
        CompanyName: "회사명",
        CompanyNameDetail: "제이에스넷 ",
        Address: "주소",
        AddressDetail:
          "대전광역시 유성구 계룡로105번길 15 2층 (보안 정책상 방문상담은 진행하지 않습니다.) ",
        Representative: "대표자",
        RepresentativeDetail: "서종우 ",
        PersonalInformationManager: "개인정보관리책임자",
        PersonalInformationManagerDetail: "김준호",
        BusinessRegistrationNumber: "사업자등록번호",
        BusinessRegistrationNumberDetail: "857-14- 02508",
        EmailDetail: "info@keywordlit.com",
        CustomerCenter: "고객센터",
        CustomerCenterDetail: " 010-6487-1736",
      },
      SignIn: {
        "Sign in": "로그인",
        "Email Address": "이메일 주소",
        Password: "비밀번호",
        "LOG IN": "로그인",
        "LOGGING IN": "로그인",
        "Finding Password": "비밀번호 찾기",
        "Don't have an KeywordLit account?": "키워드릿 계정이 없나요?",
        "Sign Up": "가입하기",
        "Enter Email Address": "이메일 주소를 입력하세요",
        "Enter the Password": "비밀번호를 입력하세요",
        "Please enter your username": "사용자 이름을 입력하세요",
        "Enter the Password": "비밀번호를 입력하세요",
      },
      Register: {
        Register: "등록하다",
        Name: "이름",
        "Enter Your Name": "당신의 이름을 입력",
        "Email Address": "이메일 주소",
        "Enter Email Address": "이메일 주소를 입력하세요",
        "Enter the Password": "비밀번호를 입력하세요",
        Password: "비밀번호",
        "Confirm Password": "비밀번호 확인",
        "Agree to our": "",
        Submit: "제출하다",
        Submitting: "제출 중",
        and: "에 동의하며",
        last: "에도 동의합니다",
      },
      ForgotPassword: {
        "Finding Password": "비밀번호 찾기",
        "Email Address": "이메일 주소",
        "Enter Email Address": "이메일 주소를 입력하세요",
        Submit: "제출하다",
        Submitting: "제출 중",
        "Verify OTP": "OTP 확인",
        "Reset Password": "암호를 재설정",
        Password: "비밀번호",
        "Confirm Password": "비밀번호 확인",
        "Enter password": "비밀번호를 입력하세요",
        "One Time Password": "일회성 암호",
        "Enter OTP": "OTP 입력",
      },

      Header: {
        "Add Funds": "충전하기",
        Settings: "설정",
        Logout: "로그 아웃",
        Login: "로그인",
        Register: "등록하다",
        C: "씨",
      },
      Settings: {
        "My info": "내 정보",
        "Deposit History": "입금 내역",
        "Search History": "검색 기록",
        Settings: "설정",
        MyInfo: {
          "Account ID": "계정 ID",
          Name: "이름",
          Email: "이메일",
          Password: "비밀번호",
          "Confirm Password": "비밀번호 확인",
          "Update Password": "비밀번호 업데이트",
          "Enter the Password": "비밀번호를 입력하세요",
          "Enter Email Address": "이메일 주소를 입력하세요",
          Submitting: "제출 중",
        },
        Deposit: {
          "Current Balance": "현재의 균형",
          "Add Fund": "기금 추가",
          "Deposit ID": "입금ID",
          Date: "날짜",
          Amount: "양",
          "Transaction ID": "거래 ID",
          Method: "방법",
          Status: "상태",
        },
        Search: {
          Platform: "플랫폼",
          Date: "날짜",
          Keyword: "예어",
        },
      },
      SearchTags: {
        Search: "찾다",
        "Type your keyword": "키워드를 입력하세요",
        Instagram: "인스 타 그램",
        Youtube: "유튜브",
        hashtags: "해시태그",
        keywords: "키워드",
        "Please wait a moment while we are requesting a list of related":
          "관련 목록을 요청하는 동안 잠시 기다려 주십시오.",
        Hashtag: "해시태그",
        Keyword: "예어",
        TotalPosts: "총 게시물",
        SearchVolume: "검색량",
        Ranking: "순위",
        EstimatedCPC: "추정된 씨피씨",
        Competition: "경쟁",
        AvgCommentsPost: "평균 댓글/게시물",
        AvgLikesPost: "평균 좋아요/게시물",
        Reels: "릴",
        AvgComments: "평균 코멘트",
        AvgLikes: "평균 좋아요",
        AvgViews: "평균 견해",
        Top5VideoTitles: "상위 5개 비디오 타이틀",
        TotalPost: "총 게시물",
        YearlyTrends: "연간 동향",
        MonthlyTrends: "월간 동향",
        WeeklyTrends: "주간 동향",
      },
      CreditPage: {
        RefundPolicy: "환불규정",
        PolicyNum01:
          "1. 남은 충전금에 대해 90일 안에 언제든지 환불 신청을 할 수 있습니다. (무상으로 적립 받은 금액은 제외) ",
        PolicyNum02:
          "2. 환불은 환불요청 주신 날짜로부터 업무일 3~5일 안에진행됩니다. (만일 토요일에 환불을 진행요청을 주신 경우, 월요일~ 수요일까지 환불진행을 해드립니다.",
        PolicyNum03:
          "3. 환불은 신청 시 회원이 결제한 카드로 남은 충전금액만큼 자동 환불되어집니다.",
        PolicyNum04:
          "4. 환불요청은 mailto:info@keyword.com 으로 이메일/환불금액/이름 확인 후 진행이 됩니다. ",
        PolicyNum05: "5. 1,000원 미만의 금액은 환불이 불가합니다. ",
        PolicyNum06:
          "6. 국내 신용카드의 결제 환불의 경우 고객센터로 연락을 주시면 확인 후 환불을 받으실 수 있습니다. 잔액 충전 후 서비스를 이용 안 하셨을 경우엔 전체환불, 서비스 제공이 완료된 경우 남은 금액 부분 환불이 가능합니다.",
      },
    },
  },
};
export const supportedLngs = {
  en: "English",
  ar: "Arabic (العربية)",
};
i18n
  .use(backend)
  .use(i18nextBrowserLanguagedetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    debug: true,
    detection: DETECTION_OPTIONS, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    supportedLngs: ["en", "ko"],
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;

import store from './storeSetup'

export * from './storeSetup'
export * from './slices/auth'
export * from './slices/instagram-tags'
export * from './slices/youtube-tags'
export * from './slices/youtube-tag-detail'
export * from './rootReducer'
export * from './hook'

export default store

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SLICE_BASE_NAME } from "./constants.js";
import { apiGetYoutubeTags } from "../../../services/YoutubeTagService.js";

const initialState = {
  loading: "",
  tags: {},
};

// debugger;
export const getYoutubeTags = createAsyncThunk(
  SLICE_BASE_NAME + "/data/getList",
  async (data) => {
    try {
      const response = await apiGetYoutubeTags(data);
      return { data: response?.data, status: response?.status };
    } catch (errors) {
      return { data: errors?.response?.data, status: errors?.response?.status };
    }
  }
);

const youtubeTagSlice = createSlice({
  name: `${SLICE_BASE_NAME}`,
  initialState,
  reducers: {
    // setUser(state, action) {
    //   state.avatar = action.payload?.avatar
    //   state.email = action.payload?.email
    //   state.userName = action.payload?.userName
    //   state.authority = action.payload?.authority
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getYoutubeTags.fulfilled, (state, { payload }) => {
        state.youtubeTags = payload.data.Hashtag;
        state.youtubeData = payload;
        state.loading = false;
      })
      .addCase(getYoutubeTags.pending, (state) => {
        state.loading = true;
      });
  },
});

export const {} = youtubeTagSlice.actions;
export default youtubeTagSlice.reducer;

import ApiService from "./ApiService";

export async function apiSignIn(data) {
  return ApiService.fetchData({
    url: "/api/login/",
    method: "post",
    data,
  });
}

export async function apiSignUp(data) {
  return ApiService.fetchData({
    url: "/api/register/",
    method: "post",
    data,
  });
}

export async function apiChangePassword(data) {
  return ApiService.fetchData({
    url: "/api/changepassword/",
    method: "post",
    data,
  });
}
export async function apiOTPVerification(data) {
  return ApiService.fetchData({
    url: "/api/verification/",
    method: "post",
    data,
  });
}

export async function apiForgotPassword(data) {
  return ApiService.fetchData({
    url: "/api/forgotpassword/",
    method: "post",
    data,
  });
}

export async function apiGetProfile() {
  return ApiService.fetchData({
    url: "/api/profile/",
  });
}

export async function apiResetPassword(data) {
  return ApiService.fetchData({
    url: "/reset-password",
    method: "post",
    data,
  });
}
export async function apiResendOTP(data) {
  return ApiService.fetchData({
    url: "/api/resendotp/",
    method: "post",
    data,
  });
}

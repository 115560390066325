import React, { useEffect, useState } from "react";
import { Squash as Hamburger } from "hamburger-react";
import logo from "../../assests/twitter_header_photo_1.png";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { apiGetProfile } from "../../services/AuthService";
// import { RxHamburgerMenu } from "react-icons/rx";

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();

  const { authenticated, signOut } = useAuth();

  const onChangeLang = (e) => {
    setOpen(false);
    i18n.changeLanguage(language === "ko" ? "en" : "ko");
  };

  const [profileData, setProfileData] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      if (authenticated) {
        apiGetProfile()
          .then((res) => {
            setProfileData(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }, 400);
  }, [authenticated]);

  return (
    <>
      <nav className="w-full flex flex-row justify-between items-center py-[16px] px-[40px] sm:px-[10px] font-Inter ">
        <div className="">
          <Link to={"/"}>
            <img
              src={logo}
              width={"150px"}
              height={"120px"}
              alt="keyword"
              className="cursor-pointer"
            />
          </Link>
        </div>
        {authenticated ? (
          <div className="xl:flex xl:flex-row lg:flex lg:flex-row md:hidden sm:hidden">
            <div className="w-auto h-auto flex flex-row justify-center items-center gap-x-[20px] ">
              <button
                className="bg-[#F2F2F2] h-[40px] flex flex-row justify-center items-center p-[15px] rounded-[8px]"
                onClick={() => navigate("./Add-funds")}
              >
                <span className="font-Inter  text-[#111111] text-[14px] leading-[1.2em]">
                  {profileData?.personal_data?.credit || "00"}
                </span>
              </button>
              <button className="bg-[#F2F2F2] h-[40px] flex flex-row justify-center items-center p-[15px] rounded-[8px]">
                {profileData?.personal_data?.first_name || "Name"}
              </button>
              <button className="bg-[#F2F2F2] h-[40px] flex flex-row justify-center items-center p-[15px]  rounded-[8px]" onClick={() => navigate("./Add-funds")}>
                {t("Header.Add Funds")}
              </button>
              <button
                onClick={() => navigate("/settings")}
                className="bg-[#F2F2F2] h-[40px] flex flex-row justify-center items-center p-[15px]  rounded-[8px]"
              >
                {t("Header.Settings")}
              </button>
              <button
                onClick={() => {
                  setOpen(false);
                  signOut();
                }}
                className="bg-[#F2F2F2] h-[40px] flex flex-row justify-center items-center p-[15px] rounded-[8px]"
              >
                {t("Header.Logout")}
              </button>
              <button
                onClick={onChangeLang}
                className="bg-[#F2F2F2] h-[40px] flex flex-row justify-center items-center p-[15px]  rounded-[8px]"
              >
                {language === "ko" ? "English" : "Korean"}
              </button>
            </div>

            {/* login and register */}
          </div>
        ) : (
          <div className="xl:flex xl:flex-row lg:flex lg:flex-row md:hidden sm:hidden">
            <div className="flex flex-row justify-center items-center flex-nowrap gap-x-[20px]">
              <Link to={"/register"} className="no-underline">
                <button className="h-[40px] flex justify-center items-center p-[15px] bg-[#222222] rounded-[8px]">
                  <span className="font-Inter font-semibold text-[#FFFFFF] text-[14px] leading-[1.2em]">
                    {t("Header.Register")}
                  </span>
                </button>
              </Link>
              <Link to={"/login"} className="no-underline">
                <button className="h-[40px] flex justify-center items-center p-[15px] bg-[#F2F2F2] rounded-[8px]">
                  <span className="font-Inter font-semibold text-[#111111] text-[14px] leading-[1.2em]">
                    {t("Header.Login")}
                  </span>
                </button>
              </Link>
              <button
                onClick={onChangeLang}
                className="bg-[#F2F2F2] h-[40px] flex flex-row justify-center items-center p-[15px]  rounded-[8px]"
              >
                {language === "ko" ? "English" : "Korean"}
              </button>
            </div>
          </div>
        )}
        <div className="xl:hidden lg:hidden md:flex sm:flex cursor-pointer">
          <Hamburger toggled={isOpen} size={30} toggle={setOpen} />
        </div>

        {authenticated && isOpen ? (
          <div className="w-auto h-auto flex flex-col absolute top-[80px] left-0 right-0 bg-white justify-center items-center gap-x-[20px] z-10 ">
            <Link to={"/add-funds"}>
              <button
                className="w-full flex flex-row justify-center items-center p-[15px] rounded-[8px]"
                onClick={() => setOpen(false)}
              >
                <span className="font-Inter  text-[#111111] text-[14px] leading-[1.2em]">
                  {profileData?.personal_data?.credit || "00"}
                </span>
              </button>
            </Link>

            <button
              className="w-full flex flex-row justify-center items-center p-[15px] rounded-[8px]"
              onClick={() => setOpen(false)}
            >
              {profileData?.personal_data?.first_name}
            </button>
            <button
              className="w-full flex flex-row justify-center items-center p-[15px] rounded-[8px]"
              onClick={() => {
                setOpen(false)
                navigate("./add-funds")
              }
              }

            >
              {t("Header.Add Funds")}
            </button>
            <button
              onClick={() => {
                setOpen(false);
                navigate("/settings");
              }}
              className="w-full flex flex-row justify-center items-center p-[15px] rounded-[8px]"
            >
              {t("Header.Settings")}
            </button>
            <button
              onClick={() => {
                setOpen(false);
                signOut();
              }}
              className="w-full flex flex-row justify-center items-center p-[15px] rounded-[8px]"
            >
              {t("Header.Logout")}
            </button>
            <button
              onClick={onChangeLang}
              className="w-full flex flex-row justify-center items-center p-[15px] rounded-[8px]"
            >
              {language === "ko" ? "English" : "Korean"}
            </button>
          </div>
        ) : isOpen ? (
          <div className="w-auto h-auto flex flex-col absolute top-[80px] left-0 right-0 bg-white justify-center items-center gap-x-[20px] z-10">
            {/* <Link to={"/register"} className="no-underline"> */}
            <button
              onClick={() => {
                setOpen(false);
                navigate("/register");
              }}
              className="w-full flex flex-row justify-center items-center p-[15px] rounded-[8px]"
            >
              <span className="font-Inter  text-[#111111] text-[14px] leading-[1.2em]">
                {t("Header.Register")}
              </span>
            </button>
            {/* </Link> */}
            {/* <Link to={"/login"} className="no-underline"> */}
            <button className="w-full flex flex-row justify-center items-center p-[15px] rounded-[8px]">
              <span
                className="font-Inter  text-[#111111] text-[14px] leading-[1.2em]"
                onClick={() => {
                  setOpen(false);
                  navigate("./login");
                }}
              >
                {t("Header.Login")}
              </span>
            </button>
            {/* </Link> */}
            <button
              onClick={onChangeLang}
              className="w-full flex flex-row justify-center items-center p-[15px] rounded-[8px]"
            >
              {language === "ko" ? "English" : "Korean"}
            </button>
          </div>
        ) : null}
      </nav>
    </>
  );
};

export default Navbar;

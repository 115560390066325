import {
  apiChangePassword,
  apiSignIn,
  apiSignUp,
} from "../services/AuthService";
import {
  setUser,
  signInSuccess,
  signOutSuccess,
  useAppSelector,
  useAppDispatch,
} from "../store";
import appConfig from "../constants/app.config";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import { useEffect, useState } from "react";
// import { sign } from "crypto";

// type Status = 'success' | 'failed'

function useAuth() {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn } = useAppSelector((state) => state.auth.session);

  const signIn = async (values) => {
    try {
      const resp = await apiSignIn(values);
      if (resp.data) {
        const { token } = resp.data;
        dispatch(signInSuccess(token));
        if (resp.data.user) {
          dispatch(
            setUser(
              resp.data.user || {
                avatar: "",
                userName: "Anonymous",
                authority: ["USER"],
                email: "",
              }
            )
          );
        }
        const redirectUrl = query.get(appConfig.redirectURL);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    } catch (errors) {
      return {
        status: "failed",
        message:
          errors?.response?.data?.errors?.non_field_errors[0] ||
          errors.toString(),
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);
      if (resp.data) {
        const { token } = resp.data;
        dispatch(signInSuccess(token));
        if (resp.data.user) {
          dispatch(
            setUser(
              resp.data.user || {
                avatar: "",
                userName: "Anonymous",
                authority: ["USER"],
                email: "",
              }
            )
          );
        }
        // const redirectUrl = query.get(appConfig.redirectURL);
        // navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    } catch (errors) {
      //  console.log(errors?.response?.data?.errors?.email[0])
      return {
        status: "failed",
        message:
          errors?.response?.data?.msg ||
          errors?.response?.data?.errors?.email[0] ||
          errors.toString(),
      };
    }
  };

  const updatePassword = async (values) => {
    try {
      const resp = await apiChangePassword(values);
      if (resp.data) {
        return {
          status: "success",
          message: "",
        };
      }
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(signOutSuccess());
    dispatch(
      setUser({
        avatar: "",
        userName: "",
        email: "",
        authority: [],
      })
    );
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    handleSignOut();
  };

  return {
    authenticated: signedIn && token,
    signIn,
    signUp,
    signOut,
    updatePassword,
  };
}

export default useAuth;

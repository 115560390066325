import React from "react";

const YtTest = () => {

    return (
        <section className="xl:w-full lg:w-full md:w-full sm:w-full flex flex-col gap-x-[60px] px-[40px] py-[100px] flex-nowrap font-Inter">
            <h1 className=" font-bold text-[#333333] text-[50px] sm:text-[40px] leading-5 tracking-tight text-center ">
                Youtube Test Video Embed
            </h1>

            <iframe
                src="https://www.youtube.com/embed/Jq-q5QSkJi4?&loop=1&playlist=Jq-q5QSkJi4;rel=0&amp;controls=1&amp;autoplay=1&amp;mute=1&amp;start=0"
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen="">
            </iframe>

        </section>
    );
};

export default YtTest;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SLICE_BASE_NAME } from "./constants.js";
import { apiGetInstaTags } from "../../../services/InstagramTagService.js";

const initialState = {
  loading: "",
  instaTags: {},
};

export const getInstagramTags = createAsyncThunk(
  SLICE_BASE_NAME + "/data/getList",
  async (data) => {
    try {
      const response = await apiGetInstaTags(data);
      return { data: response?.data, status: response?.status };
    } catch (errors) {
      return { data: errors?.response?.data, status: errors?.response?.status };
    }
  }
);

const instagramTagSlice = createSlice({
  name: `${SLICE_BASE_NAME}`,
  initialState,
  reducers: {
    // setUser(state, action) {
    //   state.avatar = action.payload?.avatar
    //   state.email = action.payload?.email
    //   state.userName = action.payload?.userName
    //   state.authority = action.payload?.authority
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInstagramTags.fulfilled, (state, { payload }) => {
        state.instaTags = payload;
        state.loading = false;
      })
      .addCase(getInstagramTags.pending, (state) => {
        state.loading = true;
      });
  },
});

export const {} = instagramTagSlice.actions;
export default instagramTagSlice.reducer;

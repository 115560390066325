import React from "react";
import logo from "../../assests/logo_transparent.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
      <footer className="bg-[#000000] w-full h-fit px-3 py-12 mx-auto sm:h-full">
        <div className="grid grid-cols-12 mt-14">
          <div className="sm:col-span-12 md:col-span-6 col-span-2 flex justify-center">
            <img
              src={logo}
              alt="keyword"
              className="cursor-pointer saturate-200"
              width={"150px"}
              height={"150px"}
              style={{filter:'invert(0.6)'}}
            />
          </div>
          <div className="text-[#999999] sm:col-span-12 md:col-span-6 col-span-6 text-center p-2 border-l border-[#999999] items-center flex flex-col justify-center h-[120px] sm:text-[14px]">
            <div className="w-full" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{width:'-webkit-fill-available', padding:'20px'}}>
              <div style={{ display: 'flex' , justifyContent:'center'}}>
                  <p style={{marginBottom:'0'}}>{t("Footer.CompanyName")} &nbsp;:&nbsp; </p>
                  <p style={{marginBottom:'0'}}>{t("Footer.CompanyNameDetail")} </p>
                </div>
                <div style={{ display: 'flex' , justifyContent:'center'}}>
                  <p style={{marginBottom:'0'}}>{t("Footer.Representative")} &nbsp;:&nbsp;</p>
                  <p style={{marginBottom:'0'}}>{t("Footer.RepresentativeDetail")} </p>
                </div>
                <div style={{ display: 'flex' , justifyContent:'center'}}>
                  <p style={{marginBottom:'0'}}>{t("Footer.PersonalInformationManager")} &nbsp;:&nbsp;</p>
                  <p style={{marginBottom:'0'}}>{t("Footer.PersonalInformationManagerDetail")} </p>
                </div>
                <div style={{ display: 'flex', justifyContent:'center' }}>
                  <p style={{marginBottom:'0'}}>{t("Footer.BusinessRegistrationNumber")} &nbsp;:&nbsp;</p>
                  <p style={{marginBottom:'0'}}>{t("Footer.BusinessRegistrationNumberDetail")} </p>
                </div>
                <div style={{ display: 'flex' , justifyContent:'center'}} >
                  <p style={{marginBottom:'0'}}>{t("Footer.Email")} &nbsp;:&nbsp;</p>
                  <p style={{marginBottom:'0'}}>{t("Footer.EmailDetail")} </p>
                </div>
                <div style={{ display: 'flex', justifyContent:'center' }}>
                  <p style={{marginBottom:'0'}}>{t("Footer.CustomerCenter")} &nbsp;:&nbsp;</p>
                  <p style={{marginBottom:'0'}}> {t("Footer.CustomerCenterDetail")} </p>
                </div>
              </div>

            </div>

          </div>
          <div className="sm:col-span-12 md:col-span-12 col-span-4 text-[#999999] border-l border-[#999999] px-4 flex flex-col justify-center h-[120px] sm:text-[14px]">
            <p style={{padding:'23px', textAlign:'center'}}>{t("Footer.footerRight")}</p>
          </div>

          <div style={{ color: 'rgb(153 153 153)', alignItems: 'center' }} className="col-span-12 flex flex-col h-[40px] justify-center align-center gap-x-[20px] py-[20px] px-[20px] sm:px-[10px] mt-20">
            <p>{t("Footer.Address")}</p>
            <p style={{ maxWidth: '600px', textAlign: 'center' }}>{t("Footer.AddressDetail")}</p>
          </div>

          <div className="col-span-12 flex flex-row h-[40px] justify-center gap-x-[20px] py-[20px] px-[20px] sm:px-[10px] mt-[15px] mb-[-10px]">
            <Link to={"terms-service"} className="no-underline">
              <p className="hover:underline font-Inter font-semibold text-[#999999] text-[15px] sm:text-[14px] leading-[2em] ">
                {t("TermsPage.heading")}
              </p>
            </Link>
            <Link to={"/privacy-policy"} className="no-underline">
              <p className="hover:underline font-Inter font-semibold text-[#999999] text-[15px] sm:text-[14px] leading-[2em] ">
                {t("PrivacyPolicy.privacy_policy")}
              </p>
            </Link>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

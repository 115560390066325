import { combineReducers } from 'redux'
import auth from './slices/auth'
import instagramTag from './slices/instagram-tags'
import youtubeTag from './slices/youtube-tags/'
import youtubeData from './slices/youtube-tags'
import RtkQueryService from '../services/RtkQueryService'
import youtubeTagDetail from './slices/youtube-tag-detail'

const staticReducers = {
  auth,
  instagramTag,
  youtubeTag,
  youtubeData,
  youtubeTagDetail,
  [RtkQueryService.reducerPath]: RtkQueryService.reducer,
}

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    ...staticReducers,
    ...asyncReducers,
  })
  return combinedReducer(state, action)
}

export default rootReducer
